import {
    createStyles,
    Badge,
    Group,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
} from '@mantine/core';
import { IconGauge, IconUser, IconCookie } from '@tabler/icons-react';
import {CommentHtml} from "./Comment";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useEffect} from "react";


const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(34),
        fontWeight: 900,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
        },
    },

    description: {
        maxWidth: 600,
        margin: 'auto',

        '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.fn.primaryColor(),
            width: rem(45),
            height: rem(2),
            marginTop: theme.spacing.sm,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },

    card: {
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
        }`,
    },

    cardTitle: {
        '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.fn.primaryColor(),
            width: rem(45),
            height: rem(2),
            marginTop: theme.spacing.sm,
        },
    },
}));

export function CommentCards() {
    const { classes, theme } = useStyles();

    const { t, i18n } = useTranslation();




    const mockdata = [
        {
            postedAt: t('reviewPostAt1'),
            body: t('reviewBody1'),
            author: t('reviewAuthor1'),
            name: t('reviewAuthor1'),
            image: t('reviewImage1')
        },
        {
            postedAt: t('reviewPostAt2'),
            body: t('reviewBody2'),
            author: t('reviewAuthor2'),
            name: t('reviewAuthor2'),
            image: t('reviewImage2')
        },
        {
            postedAt: t('reviewPostAt3'),
            body: t('reviewBody3'),
            author: t('reviewAuthor3'),
            name: t('reviewAuthor3'),
            image: t('reviewImage3')
        },
    ];

    const features = mockdata.map((feature) => (
        <CommentHtml author={feature.author} body={feature.body} postedAt={feature.postedAt} name={feature.name} image={feature.image}></CommentHtml>
    ));

    return (
        <Container size="lg" py="xl">
            <Group position="center">
                <Badge variant="filled" size="lg">
                    Best company ever
                </Badge>
            </Group>

            <Title order={2} className={classes.title} ta="center" mt="sm" color={theme.white}>
                {t('Review Slogan')}
            </Title>

            <Title order={2} size="h4" textWrap="balance"  c="dimmed" className={classes.description} ta="center" mt="md" color={theme.shadows}>
                {t('Review Detail')}
            </Title>

            <SimpleGrid cols={3} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                {features}
            </SimpleGrid>
        </Container>
    );
}