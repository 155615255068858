import { Card,  } from 'antd';
import {Link} from "react-router-dom";
const { Meta } = Card;

export function galleryCard(src,description, name){
    return (
        <Link>
            <Card
                hoverable
                bordered={true}
                style={{
                    margin: "auto",
                    width: "95%",
                    height: "95%",
                    background: "#EEE7DA"
                }
            }
                className = "custom-card"
                cover={
                    <div style={{
                        alignContent: "center", justifyContent: "center", display: "flex", margin: "auto", marginTop: "3%", width: "200px", height: "350px"}}>
                        <img alt={name} src={src}  style={{maxWidth: "350px", // Ensure the image takes the full width of its container
                            objectFit: "cover"}}/>
                    </div>
                }
            >
                <Meta title={name} description={description} style={{marginBottom: "5%"}}/>
            </Card>
        </Link>
    )
}

 function aCard(src, title, description, name, subCategory){
    return (
        <Link to={subCategory}>
            <Card
                hoverable
                bordered={true}
                style={{
                    margin: "auto",
                    width: "95%",
                    height: "95%",}}
                className = "custom-card"
                cover={
                    <div style={{
                        alignContent: "center", justifyContent: "center", display: "flex", margin: "auto", marginTop: "2px"}}>
                        <img alt={name} src={src}  style={{width: "90%", height: "90%"}}/>
                    </div>
                }
                title={ (<h4>{name}</h4>)}
            >
                <Meta description={ (<h5>{description}</h5>)} />
            </Card>
        </Link>
    )
}

export function skuCard(sku) {
     return (
         <Link to={sku['subSubCategory']+'/'+sku['id']}>
             <Card
                 hoverable
                 bordered={true}
                 style={{
                     margin: "auto",
                     width: "95%",
                     height: "95%",
                 }}
                 className = "custom-card"
                 cover={
                     <div style={{
                         alignContent: "center", justifyContent: "center", display: "flex", margin: "auto", marginTop: "2px"}}>
                         <img alt="example" src={sku['image']}  style={{width: "90%", height: "90%"}}/>
                     </div>
                 }
                 title={(<h4>{convertToTitleCase(sku['title'])}</h4>)}
             >
                 <Meta description={<h5>{convertToTitleCase(sku['description'].split('.')[0])}</h5>}  />
             </Card>
         </Link>
     )
}

export function convertToTitleCase(str) {
    // Split the string into words
    const words = str.split('-');

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words back together
    const result = capitalizedWords.join(' ');

    return result.toLowerCase().replace(/\b\w/g, firstLetter => firstLetter.toUpperCase());
}
export default aCard;