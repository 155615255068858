import { createStyles, SimpleGrid, Card, Image, Text, Container, AspectRatio } from '@mantine/core';
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {findStandProductByCategory} from "../utils/getData";



const useStyles = createStyles((theme) => ({
    card: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 600,
    },

    detail: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 400,
    }
}));

export default function StandardProductsCards() {
    const { classes } = useStyles();
    const [data, setData] = useState();


    const fetchData = async (category, sub_category) => {
        try {
            const resolvedData = await findStandProductByCategory(category, sub_category);
            setData(resolvedData);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    let { lng, category, sub_category } = useParams();

    if (lng == undefined) {
        lng = 'eng'
    }
    console.log(data)

    let path = '';
    if (category !== undefined) {
        path += category;
    }
    if (sub_category !== undefined) {
        path += '/' + sub_category;
    }


    useEffect(() => {
        fetchData(category, sub_category)
    }, [category, sub_category]);

    const cards = data ? data.map((product) => (
        <Card key={product.title} p="md" radius="md" component="a" href={`/${lng}/product/${product.id}/${product.title}`} className={classes.card}>
            <AspectRatio ratio={1920 / 1920}>
                <Image src={product.imageLink} />
            </AspectRatio>
            <Text color="dimmed" size="xs" transform="uppercase" weight={700} mt="md">
                {product.date}
            </Text>
            <Text className={classes.title} mt={5} size={"lg"}>
                {product.title}
            </Text>
            <Text className={classes.detail} mt={5} size={"xs"}>
                {product.description}
            </Text>
        </Card>
    )): <></>;

    return (
        <Container size={"xl"} py="xl">
            <SimpleGrid cols={3} spacing={"xl"} verticalSpacing={"xl"} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                {cards}
            </SimpleGrid>
        </Container>
    );
}