import Backbone from "../commons/layout";
import {useParams} from "react-router-dom";
import {findSkuByCategory} from "../../utils/getData";
import {Col, Divider, Row} from "antd";
import {convertToTitleCase, skuCard} from "../commons/card";
import React, {useEffect, useState} from "react";
import {banners} from "../commons/banners";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {ContactUs} from "../../index/components/ContactUs/contactUs";
import {Container, createStyles, Overlay, rem} from "@mantine/core";
import ancaBanner from "../../../static/anca-banner.jpg";

const useStyles = createStyles((theme) => ({
    mills1: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-ban3_0.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    banners: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-com-bg.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    zoller: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/csm_01_Slider_2280x900_04_40_7ad48e6507.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    anca: {
        position: 'relative',
        backgroundImage: 'url('+String(ancaBanner)+')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    mills2: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-ban1_0.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    products: {
        position: 'relative',
        backgroundImage:
            'url(https://embed.widencdn.net/img/harveyperformance/qfzy5hdptq/exact/Harvey_MiniTaperedLg.png?position=c&crop=no&color=ffffff00&u=r5tz5r)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    mills3: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-ban4_0.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    mills4: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-ban2_0.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    mills5: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-ban4_0.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    containerCenter: {
        size: 1200,
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: rem(500),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },

    containerCenterShort: {
        size: 1200,
        paddingTop: rem(130),
        // paddingBottom: rem(130),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',
    },

    containerCenterShortWithBottom: {
        size: 1200,
        paddingTop: rem(130),
        paddingBottom: rem(130),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',
    },
    container: {
        size: 1200,
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: rem(500),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },
    zollerPad: {
        marginTop: "auto",
        marginBottom: "auto",
        paddingLeft: "5%",
        paddingRight: "5%",
        backgroundColor: "white",
        opacity: 0.86,
        // borderRadius: "50px",
    },
    zollerContainer: {
        marginLeft: "10%",
        marginRight: "10%",
        paddingTop: "2%",
        paddingBottom: "2%",
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        position: 'relative',
        [theme.fn.smallerThan('lg')]: {
            marginLeft: "10%",
            marginRight: "10%",
            height: rem(1000),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
        [theme.fn.largerThan('xl')]: {
            marginLeft: "15%",
            marginRight: "15%",
        }
    },


    highlight: {
        color: theme.colors[theme.primaryColor][4],
    },

    containerForm: {
        height: rem(700),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        // alignItems: 'flex-start',
        paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: rem(1000),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },

    containerFeatures: {
        height: rem(700),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'flex-start',
        paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: rem(1200),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },

    containerTall: {
        size: "xl",
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: rem(500),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },

    title: {
        color: theme.white,
        fontSize: rem(60),
        fontWeight: 900,
        lineHeight: 1.1,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(40),
            lineHeight: 1.2,
        },

        [theme.fn.smallerThan('xs')]: {
            fontSize: rem(28),
            lineHeight: 1.3,
        },
    },

    titleSmall: {
        color: theme.white,
        fontSize: rem(45),
        fontWeight: 900,
        lineHeight: 1.1,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(40),
            lineHeight: 1.2,
        },

        [theme.fn.smallerThan('xs')]: {
            fontSize: rem(28),
            lineHeight: 1.3,
        },
    },

    description: {
        color: theme.white,
        maxWidth: 600,

        [theme.fn.smallerThan('sm')]: {
            maxWidth: '100%',
            fontSize: theme.fontSizes.sm,
        },
    },

    control: {
        marginTop: `calc(${theme.spacing.xl} * 1.5)`,

        [theme.fn.smallerThan('sm')]: {
            width: '100%',
        },
    },
}));

function Types() {
    let type = useParams().type
    const { classes } = useStyles();

    const { t, i18n } = useTranslation();

    const [data, setData] = useState([]);
    const lng = useParams().language;


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const resolvedData = await findSkuByCategory(type);
            setData(resolvedData);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <>
            <Helmet>
                <html lang={lng} />
                <title>{t('index-title')}</title>
                <meta name="description" content={t('index-description')}/>
                <meta name="keywords" content={t('index-keywords')}></meta>
                <link rel="canonical" href=""/>
            </Helmet>

            <div style={{margin: '2%'}}>
                <ContactUs/>
            </div>
                <div style={{margin: "2%"}}>
                {
                    data.map(
                        (category) => (
                            <div>
                                <h1>{convertToTitleCase(category.typeName)}</h1>
                                <Row gutter={[16, 20]}>
                                    {category.skus.map(
                                        item => (
                                            <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
                                                {skuCard(item)}</Col>
                                        )
                                    )}
                                </Row>
                                <br></br>
                                <Divider/>
                            </div>
                        )
                    )
                }
            </div>

        </>
    )
}
export default function SubCustomize(){
    return (
        <Backbone content={Types()}></Backbone>
    )
};