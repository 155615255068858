import {Form, Input, Modal, Typography} from "antd";
import React, {useState} from "react";
import {postQuotation} from "../../utils/getData";
const { Title, Paragraph, Text, Link } = Typography;

export default function QuotationModal(isShowQuotationModal, setIsShowQuotationModal) {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();
    const handleSubmit = async (values) => {
        setConfirmLoading(true);
        values['url'] = window.location.href
        await postQuotation(values);
        setTimeout(() => {
            setIsShowQuotationModal(false);
            setConfirmLoading(false);
        }, 2000);
    };
    const handleCancel = () => {
        setIsShowQuotationModal(false);
    };
    return (
        <Modal open={isShowQuotationModal}
               onOk={form.submit}
               onCancel={handleCancel}
               confirmLoading={confirmLoading}
               title={"Quick Quotation"}
               okText={"Send Request"}
               cancelText={"Close"}
               width={"600px"}
        >
            <Text type="secondary">Tell Us Whatever You What</Text>
            <Form
                form={form}
                onFinish={handleSubmit}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                style={{ maxWidth: 600 , marginTop: "20px"}}
            >
                <Form.Item
                    label="Name"
                    name="name"

                >
                    <Input placeholder="Name"/>
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your email!',
                        },
                        {
                            type: 'email',
                            message: 'Please enter a valid email address!',
                        },
                    ]}
                >
                    <Input placeholder="Email or else if you perfer" />
                </Form.Item>
                <Form.Item
                    label="Comment"
                    name="comment"

                >
                    <Input.TextArea rows={4} placeholder="Please let us know what you want. Thanks You and We will contact you by Email soon" multilined/>
                </Form.Item>
            </Form>
        </Modal>
    )
};