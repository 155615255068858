import {HeaderMegaMenu} from "../index/components/MegaHeader";
import {FooterLinks} from "../index/components/Footer";
import QuickGetPrice from "./QuickGetPrice";
import {Helmet} from "react-helmet";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {changeLanguage} from "i18next";
import {ActionIcon, Container, createStyles, Group, rem, SimpleGrid, Text, Title} from "@mantine/core";
import {ContactIconsList} from "../index/components/ContactUs/ContactIcons";
import {
    IconAt,
    IconBrandInstagram,
    IconBrandTwitter,
    IconBrandYoutube,
    IconMapPin,
    IconPhone,
    IconSun
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
    wrapper: {
        // minHeight: 400,
        boxSizing: 'border-box',
        backgroundImage: `linear-gradient(-60deg, ${theme.colors[theme.primaryColor][4]} 0%, ${
            theme.colors[theme.primaryColor][7]
        } 100%)`,
        borderRadius: theme.radius.md,
        padding: `calc(${theme.spacing.xl} * 2.5)`,

        [theme.fn.smallerThan('sm')]: {
            padding: `calc(${theme.spacing.xl} * 1.5)`,
        },
    },
    containerForm: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        // alignItems: 'flex-start',
        paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },
    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        color: theme.white,
        lineHeight: 1,
    },

    description: {
        color: theme.colors[theme.primaryColor][0],
        maxWidth: rem(300),

        [theme.fn.smallerThan('sm')]: {
            maxWidth: '100%',
        },
    },

    social: {
        color: theme.white,

        '&:hover': {
            color: theme.colors[theme.primaryColor][1],
        },
    },
}));

export default function QuickGetPriceIndex() {
    const { classes } = useStyles();

    const { t, i18n } = useTranslation();
    const lng = useParams().language;
    useEffect(()=>{
        changeLanguage(lng)
    });

    const social = [IconBrandTwitter, IconBrandYoutube, IconBrandInstagram];

    const icons = social.map((Icon, index) => (
        <ActionIcon key={index} size={28} className={classes.social} variant="transparent">
            <Icon size="1.4rem" stroke={1.5} />
        </ActionIcon>
    ));

    const mockdata = [
        { title: t("contactUsEmail"), description: 'sales@kytools.tools', icon: IconAt },
        { title: t("contactUsWechat"), description: 'k0n233', icon: IconPhone },
        { title: t("contactUsAddress"), description: t("contactUsAddressDescription"), icon: IconMapPin },
        { title: t("contactUsWorkHours"), description: '8 a.m. – 11 p.m.', icon: IconSun },
    ];
    return (
        <>
        <Helmet>
            <html lang={lng} />
            <title>{t('price-title')}</title>
            <meta name="description" content={t('price-description')}/>
            <meta name="keywords" content={t('price-keywords')}></meta>
            <link rel="canonical" href={`/${lng}/getPrice`}/>
            <lastmod>2024-01-01</lastmod>
        </Helmet>
        <HeaderMegaMenu></HeaderMegaMenu>
            <Container className={classes.containerForm} size={'xl'}>

                <SimpleGrid cols={2} spacing={50} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} >
                    <div className={classes.wrapper}>
                        <Title className={classes.title}>{t("contactUsTitle")}</Title>
                        <Text className={classes.description} mt="sm" mb={30}>
                            {t("contactUsDescription")}
                        </Text>

                        <ContactIconsList variant="white" data={mockdata}/>

                        <Group mt="xl">{icons}</Group>
                    </div>
                    {QuickGetPrice()}
                </SimpleGrid>
            </Container>

            <FooterLinks></FooterLinks>
        </>
    )
};