import React, {useState} from 'react';
import {Button, Descriptions, Divider, Form, Image, Collapse, Row, Col} from 'antd';
import Backbone from "../../commons/layout";
import {postBuild} from "../../../utils/getData";
import {AutoJSONForm} from "./standard/AutoJsonForm";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {convertToTitleCase} from "../../commons/card";
import {InstagramOutlined, LinkedinOutlined, MailOutlined, PhoneOutlined, WhatsAppOutlined,} from "@ant-design/icons";

const CustomizeForm = () => {
    const { t, i18n } = useTranslation();
    let [skuId, title, image, forms, data] = AutoJSONForm()
    const [form] = Form.useForm();
    const [result, setResult] = useState('');
    const [loading, setLoading] = useState(false);

    const onFinish = async  (values) => {
        setResult(JSON.stringify(values, null, 2));
        try {
            values['skuId'] = skuId
            setLoading(true);
            const response = await postBuild(values);
            console.log(response);
            setLoading(false);
            form.resetFields();
            setResult("Success")
        } catch (error) {
            console.error(error);
            setLoading(false);
            setResult("Try Again")
        }
    };

    const infos = [
        {
            key: '1',
            label: 'Raw material',
            children: 'Carbide',
        },
        {
            key: '2',
            label: 'Coating',
            children: 'ALTISIN,AlTiN, TiAlN, TiSiN, TiN, DLC,No coating',
        },
        {
            key: '3',
            label: 'HRC',
            children: 'HRC45 HRC55 HRC60 HRC65',
        },
        {
            key: '4',
            label: 'Suitable',
            children: 'Carbon Steel,Alloy Steel,Cast Iron,Hardened Steel,Stainless Steel.',
        },
        {
            key: '5',
            label: 'Place of Origin',
            children: 'Jiangsu, China',
        },
        {
            key: '6',
            label: 'Brand Name',
            children: 'KYTools (A.K.A. KongYun Tools)',
        },
        {
            key: '7',
            label: 'Stock',
            children: 'Sufficient & Ample',
        },
        {
            key: '8',
            label: 'Shipment',
            children: 'UPS/Fedex/DHL/TNT/EMS',
            span: 2,
        },
        {
            key: '10',
            label: 'Tolerance of End Mill Diameter',
            children:
                (<>1 ＜ D ≤ 6: -0.010 ～ -0.030; <br/>6 ＜D ≤ 10: -0.015 ～ -0.040;<br/>10 ＜ D≤ 20: -0.020 ～ -0.050</>),
            span: 3,
        },
        {
            key: '11',
            label: 'Time before Shipping',
            children: '3-7 Days',
            span: 3,
        },
    ];
    const  content = (title, image, forms) => (

        <div style={{ maxWidth: '800px', margin: 'auto'  ,marginBottom: 0,
            paddingTop: 20,
            paddingLeft: 30,
            paddingRight: 30,}}>
            <h1>{title && convertToTitleCase(title)}</h1>
            <Image
                src={image}
                alt={title && convertToTitleCase(title)+" kytools China"}
            />
            <h2>{data && data.description.split('.')[0]}</h2>
            <Row gutter={16} justify="center" style={{marginBottom: '12px'}}>
                <Col>
                    <a href="https://www.linkedin.com/in/镇洲-孔-2b2657165/" target="_blank" rel="noopener noreferrer">
                        <LinkedinOutlined style={{ fontSize: '40px', color: '#0a66c2' }}/>
                    </a>
                </Col>
                <Col>
                    <a href="tel:+8619916648431" target="_blank" rel="noopener noreferrer">
                        <PhoneOutlined style={{ fontSize: '40px', color: '#25D366' }}/>
                    </a>
                </Col>
                <Col>
                    <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                        <InstagramOutlined style={{ fontSize: '40px', color: '#c32aa36' }}/>
                    </a>
                </Col>
                <Col>
                    <a href="https://wa.me/8619916648431" target="_blank" rel="noopener noreferrer">
                        <WhatsAppOutlined style={{ fontSize: '40px', color: '#25d366' }}/>
                    </a>
                </Col>
                <Col>
                    <a href="mailto:sales@kytools.tools">
                        <MailOutlined style={{ fontSize: '40px', color: '#1C97ED' }}/>
                    </a>
                </Col>
            </Row>
            <Collapse
                items={[
                    {
                        key: '1',
                        label: 'Detailed Description',
                        children: <p>{data && data.description}</p>,
                    },
                ]}
            />
            <Divider orientation="left">Tool Info</Divider>

            <Descriptions title="" items={infos} bordered/>

            <Divider orientation="left">Customize Online</Divider>
            <h2>Customize and tailor your {title && convertToTitleCase(title)} to suit your precise specifications, including length, diameter, and other custom features of your choice.</h2>
            <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                layout="horizontal"
                onFinish={onFinish}
                style={{
                    marginTop: 20,
                    paddingBottom: 10,
                    paddingLeft: 30,
                    paddingRight: 30,
                }}
            >
                <h3>刀具参数 / Tool Information</h3>
                {forms}
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        提交 / Submit
                    </Button>
                </Form.Item>
            </Form>
            {result && (
                <div>
                    <h3>提交结果 / Submit Result:</h3>
                    <pre>{result}</pre>
                    {/*<p>成功！/ Success</p>*/}

                </div>
            )}
        </div>

    )

    const lng = useParams().language;


    return (
        <>
            <Helmet>
                <html lang={lng} />
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                {data && data.seoTitle && (<title>{data.seoTitle}</title>)}
                {data && data.seoDescription && (<meta name="description" content={data.seoDescription}/>)}
                {data && data.seoKeywords && (<meta name="keywords" content={data.seoKeywords}></meta>)}
                {data &&(<link rel="canonical" href={`/eng/customize/${data.subCategory}/${data.subSubCategory}/${data.id}`}/>)}
                <meta name="author" content="KYTools"/>
                <meta name="robots" content="index, follow"/>
            </Helmet>
            <Backbone content={content(title, image, forms)}>
            </Backbone>
        </>

    )
};

export default CustomizeForm;
