import {HeaderMegaMenu} from "../index/components/MegaHeader";
import {FooterLinks} from "../index/components/Footer";
import {StandardNavBar} from "./NavBar";
import StandardProductsCards from "./StandardProductsCards";

export default function StandardProductsIndex() {
    return <>
        <HeaderMegaMenu></HeaderMegaMenu>
        <StandardNavBar content={StandardProductsCards()}></StandardNavBar>
        <FooterLinks></FooterLinks>
    </>
};