export  function EngMap(){
    return <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1692.5597856994475!2d119.7989998!3d31.9576527!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35b417db4faab823%3A0x943f58dacf5b9fd8!2zQ2hpbmEsIEppYW5nIFN1IFNoZW5nLCBDaGFuZyBaaG91IFNoaSwgWGluIEJlaSBRdSwg5Li95rGf6LevNTLlj7cg6YKu5pS_57yW56CBOiAyMTMxODE!5e0!3m2!1sen!2sjp!4v1688720174746!5m2!1sen!2sjp"
        width="100%" height="450px" allowFullScreen="" loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
}

export  function ZhMap(){
    return <iframe
        src="https://uri.amap.com/marker?position=119.80,31.958&name=孔赟刀具 常州西夏墅镇丽江路52号&src=mypage&coordinate=gaode&callnative=0"
        width="100%" height="450"  allowFullScreen="" loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
}