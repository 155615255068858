import React, {useEffect, useState} from "react";
import {getPostList} from "../utils/getData";
import {Helmet} from "react-helmet";
import {HeaderMegaMenu} from "../index/components/MegaHeader";
import {FooterLinks} from "../index/components/Footer";
import { Avatar, List, Space } from 'antd';
import { LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';
import {Content} from "antd/es/layout/layout";

export default function BlogList() {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const resolvedData = await getPostList();
            setData(resolvedData);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const IconText = ({ icon, text }) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );
    return <>
        <Helmet>
            <link rel="canonical" href=""/>
        </Helmet>
        <HeaderMegaMenu></HeaderMegaMenu>
        <Content style={{paddingLeft: "5%", paddingRight: "5%", paddingTop: "1%", paddingBottom: "1%"}} >

        <List
            itemLayout="vertical"
            size="large"
            pagination={{
                onChange: (page) => {
                    console.log(page);
                },
                pageSize: 10,
            }}
            dataSource={data}
            footer={
                <div>
                    <b>KYTools</b> Posts
                </div>
            }
            renderItem={(item) => (
                <List.Item
                    key={item.title}
                    actions={[
                        <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
                        <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
                        <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
                    ]}
                    // extra={
                    //     <img
                    //         width={272}
                    //         alt="logo"
                    //         src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                    //     />
                    // }
                >
                    <List.Item.Meta
                        avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${item.postId}`}/>}
                        title={<a href={`blog/${item.postId}`}>{item.title}</a>}
                        description={item.description}
                    />
                    {item.content}
                </List.Item>
            )}
        />
        </Content>

        <FooterLinks></FooterLinks>
    </>
};