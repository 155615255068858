import { createStyles, SimpleGrid, Card, Image, Text, Container, AspectRatio } from '@mantine/core';
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";



const useStyles = createStyles((theme) => ({
    card: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 600,
    },

    detail: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 400,
    }
}));

export default function ProductsCards() {
    const { classes } = useStyles();
    const [data, setData] = useState();



    let { lng, category, sub_category, product } = useParams();

    if (lng == undefined) {
        lng = 'eng'
    }
    console.log(data)

    let path = '';



    useEffect(() => {
        setData([
            {
                'title': 'Square End Mills',
                'link': 'Solid Carbide End Mills/Square End Mills 2 Flute',
                'imageLink': 'https://embed.widencdn.net/img/harveyperformance/7hi2ezqn0e/exact/Harvey_MiniSquare_Sm.png?position=c&crop=no&color=ffffff00&u=r5tz5r',
                'description': 'KyTools offering of square profile End Mills includes Stub & Standard, Long Reach, Reduced Shank, Tapered, and Long Flute designs, among others. Choose from diameter sizes as miniature as .001" and use your new tool in your machine tomorrow morning.',
            },
            {
                'title': 'Ball End Mills',
                'link': 'Solid Carbide End Mills/Ball End Mills 2 Flute',
                'imageLink': 'https://embed.widencdn.net/img/harveyperformance/rqyv5smzba/exact/Harvey_MiniBall_Sm.png?position=c&crop=no&color=ffffff00&u=r5tz5r',
                'description': 'Create complex three-dimensional contours and a smooth part finish with this offering of ball profile Miniature End Mills, fully stocked in multiple flute lengths, reaches up to 25x cutter diamater, and sizes as miniature as .002" diameter.',
            },
            {
                'title': 'Corner Radius Mills',
                'link': 'Solid Carbide End Mills/Corner Radius End Mills 4 Flute',
                'imageLink': 'https://embed.widencdn.net/img/harveyperformance/k7pg4k72yc/exact/Harvey_CornerRadius_Sm.png?position=c&crop=no&color=ffffff00&u=r5tz5r',
                'description': 'KYTools Corner Radius Miniature End Mills are stocked in a variety of radii for fast material removal and increased tool strength. Enjoy the excellent rigidity and wear resistance these tools provide in even the toughest of materials.',
            },
            {
                'title': 'Roughing End Mills',
                'link': 'Roughing End Mills',
                'imageLink': 'https://embed.widencdn.net/img/harveyperformance/562iqbsjfb/exact/Harvey_MaterialSP_Composites_Chipbreaker_Sm.png?position=c&color=ffffff00&quality=80&u=rkinig',
                'description': 'This offering includes optimized geometry with chipbreakers, designed to efficiently shear fibers and shorten chips for improved chip removal.',
            },
            {
                'title': 'Spotting Drills',
                'link': 'Spotting Drill',
                'imageLink': 'https://embed.widencdn.net/img/harveyperformance/03x0ybdayq/exact/Harvey_Holemaking_SpottingDrills_Sm.png?position=c&color=ffffff00&quality=80&u=rkinig',
                'description': 'With a thinned web that reduces walking and a self-centering point geometry, this offering of fully stocked Spotting Drills is optimized to deliver precision accuracy.',
            },
            {
                'title': 'Long Neck (Reach)',
                'link': 'Long Neck End Mill',
                'imageLink': 'https://embed.widencdn.net/img/harveyperformance/dr5snseyqd/exact/Harvey_MiniSquare_LongReach_Sm.png?position=c&color=ffffff00&quality=80&u=rkinig',
                'description': 'This KYTools offering long reach End Mills is optimized to provide rigidity in deep pocketing applications.',
            },
        ])
        // fetchData(category, sub_category)
    }, [category, sub_category]);

    const cards = data ? data.map((product) => (
        <Card key={product.title} p="md" radius="md" component="a" href={`/${lng}/products/${product.link}`} className={classes.card}>
            <AspectRatio ratio={1920 / 1080}>
                <Image src={product.imageLink} />
            </AspectRatio>
            <Text color="dimmed" size="xs" transform="uppercase" weight={700} mt="md">
                {product.date}
            </Text>
            <Text className={classes.title} mt={5} size={"lg"}>
                {product.title}
            </Text>
            <Text className={classes.detail} mt={5} size={"xs"}>
                {product.description}
            </Text>
        </Card>
    )): <></>;

    return (
        <Container size={"xl"} py="xl">
            <SimpleGrid cols={3} spacing={"xl"} verticalSpacing={"xl"} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                {cards}
            </SimpleGrid>
        </Container>
    );
}