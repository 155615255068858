import CustomizeForm from "../customizeForm";
import {
    checkBoxForm,
    Coatings, Contacts, CutDirection,
    Flutes,
    Helix,  NumberForm,
     Quantity,
     WorkpieceMaterial
} from "../../../commons/customizeFormItems";
import { useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import { findSkuById} from "../../../../utils/getData";

export const AutoJSONForm = () => {
    const id = useParams().id
    const [data, setData] = useState();
    let formSet = undefined
    let formList = []
    let checkBoxList = []
    useEffect(() => {
        fetchData().then();
    }, []);

    const fetchData = async () => {
        try {
            const resolvedData = await findSkuById(id);
            setData(resolvedData);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    if (typeof data !== 'undefined' && data !== null){
        console.log('data: '+data)
        const meta = JSON.parse(data['meta'])
        // console.log(meta)
        formSet = new Set()
        for (const form of meta) {
            formSet.add(form['name'])
            if (form['name'] === undefined) {
                continue
            }
            if (form['name'].includes('Helix')) {
                formList.unshift(Helix(true));
            }
            else if (form['name'].includes("Flutes")) {
                formList.push(Flutes(true));
            } else if (form['type'].includes("Number")) {
                formList.push(NumberForm(form));
            } else if (form['type'].includes("Checkbox")) {
                checkBoxList.push(checkBoxForm(form))
            }
        }

        formList.push(
            Coatings(true),
            CutDirection(true),
            WorkpieceMaterial(true))
        formList = formList.concat(checkBoxList)
        formList.push(
            Quantity(true),
            Contacts(true))

        // console.log(formSet)
        // console.log(formList)
        // console.log(checkBoxList)
    }

    return [
        id,
        data && data['name'],
        data && data['image'],
        formList,
        data
    ]

}