import {
  createStyles,
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  ActionIcon,
  rem,
} from '@mantine/core';
import {
  IconBrandTwitter,
  IconBrandYoutube,
  IconBrandInstagram,
  IconAt,
  IconPhone,
  IconMapPin, IconSun
} from '@tabler/icons-react';
import {ContactIconsList} from "./ContactIcons";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {useForm} from "@mantine/form";
import {postBuild} from "../../../utils/getData";
const useStyles = createStyles((theme) => ({
  wrapper: {
    // minHeight: 400,
    boxSizing: 'border-box',
    backgroundImage: `linear-gradient(-60deg, ${theme.colors[theme.primaryColor][4]} 0%, ${
        theme.colors[theme.primaryColor][7]
    } 100%)`,
    borderRadius: theme.radius.md,
    padding: `calc(${theme.spacing.xl} * 2.5)`,

    [theme.fn.smallerThan('sm')]: {
      padding: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.white,
    lineHeight: 1,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    maxWidth: rem(300),

    [theme.fn.smallerThan('sm')]: {
      maxWidth: '100%',
    },
  },

  form: {
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
  },

  social: {
    color: theme.white,

    '&:hover': {
      color: theme.colors[theme.primaryColor][1],
    },
  },

  input: {
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,

    '&::placeholder': {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    color: theme.black,
  },

  control: {
    backgroundColor: theme.colors[theme.primaryColor][6],
  },
}));

const social = [IconBrandTwitter, IconBrandYoutube, IconBrandInstagram];

export function ContactUs() {
  const { classes } = useStyles();

  const icons = social.map((Icon, index) => (
      <ActionIcon key={index} size={28} className={classes.social} variant="transparent">
        <Icon size="1.4rem" stroke={1.5} />
      </ActionIcon>
  ));

  const { t, i18n } = useTranslation();


  const mockdata = [
    { title: t("contactUsEmail"), description: 'sales@kytools.tools', icon: IconAt },
    { title: t("contactUsWechat"), description: 'k0n233', icon: IconPhone },
    { title: t("contactUsAddress"), description: t("contactUsAddressDescription"), icon: IconMapPin },
    { title: t("contactUsWorkHours"), description: '8 a.m. – 11 p.m.', icon: IconSun },
  ];

  const form = useForm(
      {
        initialValues: {
          email: '',
          name: '',
          text: ''
        },
      }
  )

  return (
      <div className={classes.wrapper}>
        <SimpleGrid cols={2} spacing={50} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} >
          <div>
            <Title className={classes.title}>{t("contactUsTitle")}</Title>
            <Title order={2} size="h4" textWrap="balance"  className={classes.description} mt="sm" mb={30}>
              {t("contactUsDescription")}
            </Title>

            <ContactIconsList variant="white" data={mockdata}/>

            <Group mt="xl">{icons}</Group>
          </div>
          <div className={classes.form}>
            <form onSubmit={form.onSubmit((values)=>postBuild(values))}>
              <TextInput
                  label={t("contactUsEmail")}
                  placeholder={t("contactUsEmailPlaceholder")}
                  required
                  classNames={{ input: classes.input, label: classes.inputLabel }}
                  {...form.getInputProps('email')}
              />
              <TextInput
                  label={t("contactUsName")}
                  placeholder={t("contactUsNamePlaceholder")}
                  mt="md"
                  classNames={{ input: classes.input, label: classes.inputLabel }}
                  {...form.getInputProps('name')}

              />
              <Textarea
                  required
                  label={t("contactUsMessage")}
                  placeholder={t("contactUsMessagePlaceholder")}
                  minRows={4}
                  mt="md"
                  classNames={{ input: classes.input, label: classes.inputLabel }}
                  {...form.getInputProps('text')}
              />

              <Group position="right" mt="md">
                <Button className={classes.control} type="submit">{t("contactUsButton")}</Button>
              </Group>
            </form>

          </div>
        </SimpleGrid>
      </div>
  );
}