import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Route, BrowserRouter, Routes, Navigate} from 'react-router-dom'
import {IndexPage} from "./pages/index/indexPage";
import './i18n';
import Customize from "./pages/cutomize/customize/customize";
import SubCustomize from "./pages/cutomize/customize/subCustomize";
import CustomizeForm from "./pages/cutomize/customize/types/customizeForm";
import GalleryIndex from "./pages/gallery/galleryIndex";
import QuickGetPriceIndex from "./pages/price/QuickGetPriceIndex";
import {ContactUsIndex} from "./pages/index/components/ContactUs/ContactUsIndex";
import StandardProductsIndex from "./pages/products/StandardProductIndex";
import {ProductPageIndex} from "./pages/products/ProductPageIndex";
import BlogList from "./pages/blog/blogList";
import BlogPage from "./pages/blog/blogPage";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                {/*<Route path='/' element={<Navigate to='/customize' />} />*/}
                <Route path='/' element={<Navigate to="/eng/"/>}/>
                <Route path='/:language/' element={<IndexPage/>}></Route>
                <Route path='/:language/customize/' element={<Customize />} />
                <Route path='/:language/contact/' element={<ContactUsIndex />} />
                <Route path='/:language/customize/:type/' element={<SubCustomize />}></Route>
                <Route path='/:language/customize/:type/:subtype/:id?' element={<CustomizeForm />}></Route>
                <Route path='/:language/products/:category?/:sub_category?' element={<StandardProductsIndex/>}></Route>
                <Route path='/:language/product/:id?/:productName?' element={<ProductPageIndex/>}></Route>
                <Route path='/:language/gallery/' element={<GalleryIndex/>}></Route>
                <Route path='/:language/getPrice/' element={<QuickGetPriceIndex/>}></Route>
                <Route path='/:language/test' element={<StandardProductsIndex/>}></Route>
                <Route path='/:language/blog' element={<BlogList></BlogList>}></Route>
                <Route path='/:language/blog/:id' element={<BlogPage></BlogPage>}></Route>
                {/*<Route path='*' element={<RedirectToHome/>}></Route>*/}
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
