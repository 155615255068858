import {
    createStyles,
    Image,
    Container,
    Title,
    Button,
    Group,
    Text,
    List,
    ThemeIcon,
    rem, Grid,
} from '@mantine/core';
import {IconCheck, IconExternalLink} from '@tabler/icons-react';
import {ThumbUp} from "tabler-icons-react";
import mx7 from "../../../static/mx7.jpg";
import ancaLogo from "../../../static/anca-logo.png";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useEffect} from "react";

const useStyles = createStyles((theme) => ({
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl} * 4)`,
        paddingBottom: `calc(${theme.spacing.xl} * 4)`,
    },

    content: {
        maxWidth: rem(480),
        marginRight: `calc(${theme.spacing.xl} * 3)`,

        [theme.fn.smallerThan('md')]: {
            maxWidth: '100%',
            marginRight: 0,
        },
    },

    title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(38),
        lineHeight: 1.2,
        fontWeight: 700,

        [theme.fn.smallerThan('xs')]: {
            fontSize: rem(28),
        },
    },

    subTitle: {
        flex: 4,
        fontSize: rem(34),
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            flex: 1,
        },
    },


    image: {
        flex: 1,

        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    highlight: {
        position: 'relative',
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
    },

    secHighlight: {
        position: 'relative',
        backgroundColor: "#FF6969",
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
    },
}));

export function ANCA() {
    const { classes } = useStyles();
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const lng = useParams().language;

    useEffect(()=>{
    });
    const mx7 = require('../../../static/mx7.jpg')
    const ancaLogo = require('../../../static/anca-logo.png')
    const HEADER_HEIGHT = rem(60);

    return (
        <div>
            <Container>
                <div className={classes.inner} style={{display: "flex"}}>
                    <Grid>
                        <Grid.Col span={12} md={6}>
                            <div className={classes.content} style={{flex: 1}}>
                                <Title className={classes.title}>
                                    <span >{t("anca-slogan")}</span>  <br />
                                    <div style={{ display: 'flex', marginTop: "20px"}}>
                                        <Image src={String(ancaLogo)} style={{flex: 1}} />
                                        <span className={classes.subTitle}>MX7 Linear</span>
                                    </div>
                                </Title>
                                <Text color="dimmed" mt="md">
                                    {t("anca-detail")}
                                </Text>

                                <List
                                    mt={30}
                                    spacing="sm"
                                    size="sm"
                                    icon={
                                        <ThemeIcon size={20} radius="xl">
                                            <IconCheck size={rem(12)} stroke={1.5} />
                                        </ThemeIcon>
                                    }
                                >
                                    <List.Item>
                                        <b>{t("anca-list-1-1")}</b>{t("anca-list-1-2")}
                                    </List.Item>
                                    <List.Item>
                                        <b>{t("anca-list-2-1")}</b>{t("anca-list-2-2")}
                                    </List.Item>
                                    <List.Item>
                                        <b>{t("anca-list-3-1")}</b>{t("anca-list-3-2")}
                                    </List.Item>
                                </List>

                                <Group mt={30}>
                                    <Button radius="xl" size="md" className={classes.control} component="a" href={"https://machines.anca.com/Products/Machines/MX-Linear/MX7-Linear"} leftIcon={<ThumbUp size="0.9rem" /> }  target="_blank">
                                        {t("anca-bt-1")}
                                    </Button>
                                    <Button variant="default" radius="xl" size="md" className={classes.control} component="a" href={"https://machines.anca.com/Anca.CNC/media/Images/Brochures/ANCA_MX_Linear_2022.pdf?ext=.pdf"} leftIcon={<IconExternalLink size="0.9rem" /> }  target="_blank">
                                        {t("anca-bt-2")}
                                    </Button>
                                </Group>
                            </div>
                        </Grid.Col>
                        <Grid.Col span={12}  md={6}>
                            <Image src={String(mx7)} className={classes.image} style={{flex: 1}}/>
                        </Grid.Col>
                    </Grid>
                </div>
            </Container>
        </div>
    );
}

export function Zoller() {
    const { classes } = useStyles();
    const mx7 = require('../../../static/mx7.jpg')
    const zollerImage = require('../../../static/zoller1.png')
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const lng = useParams().language;

    useEffect(()=>{
    });
    return (
        <div>
            <Container>
                <div className={classes.inner} style={{display: "flex"}}>
                    <Grid>
                        <Grid.Col span={12} md={6}>
                            <div className={classes.content} style={{flex: 1}}>

                        <Title className={classes.title}>
                            <span >{t("zoller-slogan")}</span>  <br />
                            <div style={{ display: 'flex', marginTop: "20px"}}>
                                <Image src='https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/svgs/ZOLLER-Logo_EN.SVG' style={{flex: 1,  marginRight: "20px"}} height={'10%'}/>
                                <span className={classes.subTitle}>venturion 450</span>
                            </div>
                        </Title>
                        <Text color="dimmed" mt="md">
                            {t("zoller-detail")}
                        </Text>

                        <List
                            mt={30}
                            spacing="sm"
                            size="sm"
                            icon={
                                <ThemeIcon size={20} radius="xl">
                                    <IconCheck size={rem(12)} stroke={1.5} />
                                </ThemeIcon>
                            }
                        >
                            <List.Item>
                                <b>{t("zoller-list-1-1")}</b>{t("zoller-list-1-2")}
                            </List.Item>
                            <List.Item>
                                <b>{t("zoller-list-2-1")}</b>{t("zoller-list-2-2")}
                            </List.Item>
                            <List.Item>
                                <b>{t("zoller-list-3-1")}</b>{t("zoller-list-3-2")}
                            </List.Item>
                        </List>

                        <Group mt={30}>
                            <Button radius="xl" size="md" className={classes.control} component="a" href={"https://www.zoller.info/us/products/presetting-measuring/vertical-devices/venturion"} leftIcon={<ThumbUp size="0.9rem" /> }  target="_blank">
                                {t("zoller-bt-1")}
                            </Button>
                            <Button variant="default" radius="xl" size="md" className={classes.control} component="a" href={"https://machines.zoller.com/zoller.CNC/media/Images/Brochures/zoller_MX_Linear_2022.pdf?ext=.pdf"} leftIcon={<IconExternalLink size="0.9rem" /> }  target="_blank">
                                {t("zoller-bt-2")}
                            </Button>
                        </Group>
                    </div>
                        </Grid.Col>
                        <Grid.Col span={12}  md={6}>
                            <Image src={String(zollerImage)} className={classes.image} style={{flex: 1}}/>
                        </Grid.Col>
                    </Grid>
                </div>
            </Container>
        </div>
    );
}