import {HeaderMegaMenu} from "../index/components/MegaHeader";
import {StandardNavBar} from "./NavBar";
import StandardProductsCards from "./StandardProductsCards";
import {FooterLinks} from "../index/components/Footer";
import {ProductPage} from "./ProductPage";

export function ProductPageIndex() {

    return <>
        <HeaderMegaMenu></HeaderMegaMenu>
        {ProductPage()}
        <FooterLinks></FooterLinks>
    </>
}