import React, {useEffect, useState} from "react";
import {getPost, getPostList} from "../utils/getData";
import {Helmet} from "react-helmet";
import {HeaderMegaMenu} from "../index/components/MegaHeader";
import {FooterLinks} from "../index/components/Footer";
import {Layout, Space, Typography} from 'antd';
import {useParams} from "react-router-dom";
const { Header, Footer, Sider, Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

export default function BlogPage() {
    const [data, setData] = useState();

    useEffect(() => {
        fetchData();
    }, []);

    let id = useParams().id


    const fetchData = async () => {
        try {
            const resolvedData = await getPost(id);
            setData(resolvedData);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return <>

        <HeaderMegaMenu></HeaderMegaMenu>

        <Space style={{paddingLeft: "5%", paddingRight: "5%", paddingTop: "1%", paddingBottom: "1%", display: 'flex',
            justifyContent: 'center', }} >
            {data &&
                <>
                    <Helmet>
                        <title>{data.title} </title>
                        <meta name="description" content={data.seoDescription}/>
                        <meta name="keywords" content={data.seoKeywords}/>
                        <meta name="author" content={data.author}/>
                        <meta name="publisher" content={"kytools"} /> {/* Add publisher meta tag */}

                        <meta name="robots" content="index, follow"/>
                        {/* Adjust as per your needs */}
                        <meta property="og:title" content={data.title}/>
                        <meta property="og:description" content={data.seoDescription}/>
                        <meta property="og:image" content=""/>
                        <meta property="og:url" content={`https://kytools.tools/eng/blog/${data.postId}`}/>
                        <meta property="og:type" content="website"/>
                        <link rel="canonical" href={`https://kytools.tools/eng/blog/${data.postId}`}/>

                    </Helmet>
                    <Typography style={{maxWidth: "1000px"}}>
                    <Title>{data.title}</Title>
                        <Paragraph>
                            <strong>Author:</strong> {data.author}
                        </Paragraph>
                        <Paragraph>
                            <strong>Last Updated:</strong> {data.updateTime}
                        </Paragraph>
                        <Paragraph>
                            {data.body}
                        </Paragraph>
                    </Typography>
                </>

            }
        </Space>

        <FooterLinks></FooterLinks>
    </>
};