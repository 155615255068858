import { createStyles, Text, Container, ActionIcon, Group, rem } from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram } from '@tabler/icons-react';
import { MantineLogo } from '@mantine/ds';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import logo from "../../../static/ico.jpeg";

const useStyles = createStyles((theme) => ({
    footer: {
        // marginTop: rem(120),
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        paddingBottom: `calc(${theme.spacing.xl} * 2)`,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        borderTop: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        }`,
    },

    logo: {
        maxWidth: rem(200),

        [theme.fn.smallerThan('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },

    description: {
        marginTop: rem(5),

        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.xs,
            textAlign: 'center',
        },
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },

    groups: {
        display: 'flex',
        flexWrap: 'wrap',

        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    wrapper: {
        width: rem(160),
    },

    link: {
        display: 'block',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
        fontSize: theme.fontSizes.sm,
        paddingTop: rem(3),
        paddingBottom: rem(3),

        '&:hover': {
            textDecoration: 'underline',
        },
    },

    title: {
        fontSize: theme.fontSizes.lg,
        fontWeight: 700,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        marginBottom: `calc(${theme.spacing.xs} / 2)`,
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },

    afterFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing.xl,
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
        borderTop: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },

    social: {
        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.xs,
        },
    },
}));



export function FooterLinks() {
    const { classes } = useStyles();

    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const lng = useParams().language;



    const data= [
        {
            "title": t("Home"),
            "links": [
                { "label": "Features", "link": "#" },
                { "label": "Pricing", "link": "#" },
                { "label": "Support", "link": "#" },
                { "label": "Forums", "link": "#" }
            ]
        },
        {
            "title": t("End Mills"),
            "links": [
                { "label": "Ball", "link": "#" },
                { "label": "Square", "link": "#" },
                { "label": "Corner Radius ", "link": "#" },
                { "label": "Stepped", "link": "#" },
                { "label": "Tapered", "link": "#" }
            ]
        },
        {
            "title": t("Specialty Profiles"),
            "links": [
                { "label": t("Back Deburring Mill"), "link": "#" },
            ]
        },
        {
            "title": t("Hole Making"),
            "links": [
                { "label": t("Counterbores"), "link": "#" },
                { "label": t("Drills"), "link": "#" },
                { "label": t("Reamers"), "link": "#" },
                { "label": t("Threadmills"), "link": "#" },
            ]
        }
    ]

    const groups = data.map((group) => {
        const links = group.links.map((link, index) => (
            <Text
            key={index}
            className={classes.link}
            component="a"
            href={link.link}
            onClick={(event) => event.preventDefault()}
            >
        {link.label}
    </Text>
    ));

        return (
            <div className={classes.wrapper} key={group.title}>
                <Text className={classes.title}>{group.title}</Text>
                {links}
            </div>
        );
    });

    return (
        <footer className={classes.footer}>
            <Container className={classes.inner}>
                <div className={classes.logo}>
                    <img alt = 'logo' style={{'width':'170px'}} src={String(logo)} />
                    <Text size="xs" color="dimmed" className={classes.description}>
                        {t("sloganDetail1")}
                    </Text>
                </div>
                <div className={classes.groups}>{groups}</div>
            </Container>
            <Container className={classes.afterFooter}>
                <Text color="dimmed" size="sm">
                    © 2023 kytools.tools. All rights reserved.
                </Text>
                <a rel="nofollow" href="http://beian.miit.gov.cn/" target="_blank">沪ICP备2024098376号</a>

                <Group spacing={0} className={classes.social} position="right" noWrap>
                    <ActionIcon size="lg">
                        <IconBrandTwitter size="1.05rem" stroke={1.5}/>
                    </ActionIcon>
                    <ActionIcon size="lg">
                        <IconBrandYoutube size="1.05rem" stroke={1.5}/>
                    </ActionIcon>
                    <ActionIcon size="lg">
                        <IconBrandInstagram size="1.05rem" stroke={1.5}/>
                    </ActionIcon>
                </Group>
            </Container>
        </footer>
    );
}