import {
    createStyles,
    Badge,
    Group,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
} from '@mantine/core';
import { IconGauge, IconUser, IconCookie } from '@tabler/icons-react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useEffect} from "react";



const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(34),
        fontWeight: 900,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
        },
    },

    description: {
        maxWidth: 600,
        margin: 'auto',

        '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.fn.primaryColor(),
            width: rem(45),
            height: rem(2),
            marginTop: theme.spacing.sm,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },

    card: {
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
        }`,
    },

    cardTitle: {
        '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.fn.primaryColor(),
            width: rem(45),
            height: rem(2),
            marginTop: theme.spacing.sm,
        },
    },
}));

export function FeaturesCards() {
    const { classes, theme } = useStyles();
    const { t, i18n } = useTranslation();





    const mockdata = [
        {
            title: t('features-description-title1'),
            description: t('features-description-des1'),
            icon: IconGauge,
        },
        {
            title: t('features-description-title2'),
            description: t('features-description-des2'),
            icon: IconUser,
        },
        {
            title: t('features-description-title3'),
            description: t('features-description-des3'),
            icon: IconCookie,
        },
    ];
    const features = mockdata.map((feature) => (
        <Card key={feature.title} shadow="md" radius="md" className={classes.card} padding="xl">
            <feature.icon size={rem(50)} stroke={2} color={theme.fn.primaryColor()} />
            <Title order={2} size="h4" textWrap="balance"  fz="lg" fw={500} className={classes.cardTitle} mt="md">
                {feature.title}
            </Title>
            <Title order={2} size="h4" textWrap="balance"  fz="sm" c="dimmed" mt="sm">
                {feature.description}
            </Title>
        </Card>
    ));

    return (
        <Container size="lg" py="xl">
            <Group position="center">
                <Badge variant="filled" size="lg">
                    {t("features-slogan")}
                </Badge>
            </Group>

            <Title  order={1} size="h4" textWrap="balance"  className={classes.title} ta="center" mt="sm" color={theme.white}>
                {t("features-title")}
            </Title>

            <Title  order={2} size="h4" textWrap="balance"  c="dimmed" className={classes.description} ta="center" mt="md" color={theme.shadows}>
                {t("features-description")}
            </Title>

            <SimpleGrid cols={3} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                {features}
            </SimpleGrid>
        </Container>
    );
}