import React from 'react';
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import {useParams} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
const { Header, Content, Footer, Sider } = Layout;

const categories = [
    {
        key: 'Solid Carbide End Mills',
        label: 'Solid Carbide End Mills',
        children: [
            {
                key: 'Square End Mills 2 Flute',
                label: 'Square End Mills 2 Flute'
            },
            {
                key: 'Square End Mills 4 Flute',
                label: 'Square End Mills 4 Flute'
            },
            {
                key: 'Ball End Mills 2 Flute',
                label: 'Ball End Mills 2 Flute'
            },
            {
                key: 'Ball End Mills 4 Flute',
                label: 'Ball End Mills 4 Flute'
            },
            {
                key: 'Corner Radius End Mills 4 Flute',
                label: 'Corner Radius End Mills 4 Flute'
            },
            {
                key: 'Micro Square End Mill (HRC 55)',
                label: 'Micro Square End Mill (HRC 55)'
            },
            {
                key: 'Micro Ball End Mill (HRC 55)',
                label: 'Micro Ball End Mill (HRC 55)'
            },
        ]
    },
    {
        key: 'End Mill For Aluminum',
        label: 'End Mill For Aluminum',
        children: [
            {
                key: 'End Mill 2 Flute For Aluminum',
                label: 'End Mill 2 Flute For Aluminum'
            },
            {
                key: 'End Mill 3 Flute For Aluminum',
                label: 'End Mill 3 Flute For Aluminum'
            },
            {
                key: 'Ball End Mill 2 Flute For Aluminum',
                label: 'Ball End Mill 2 Flute For Aluminum'
            }
        ]
    },
    {
        key: 'Roughing End Mills',
        label: 'Roughing End Mills',
        children: [
            {
                key: 'Roughing End Mills for Steel',
                label: 'Roughing End Mills for Steel'
            },
            {
                key: 'Roughing End Mills for Aluminum',
                label: 'Roughing End Mills for Aluminum'
            }
        ]
    },
    {
        key: 'Spotting Drill',
        label: 'Spotting Drill'
    },
    {
        key: 'Long Neck End Mill',
        label: 'Long Neck End Mill'
    },
    {
        key: 'Carbide Rod',
        label: 'Carbide Rod'
    },
    {
        key: 'Twist Drill',
        label: 'Twist Drill'
    },
    {
        key: 'Customize Tools',
        label: 'Customize Tools'
    }

]
export const StandardNavBar = ({content}) => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const navigate = useNavigate()

    let { language, category, sub_category } = useParams();

    const paths = [
                    {
                        title: <a href={`/${language}`}>Home</a>
                    },
                    {
                        title: <a href={`/${language}/products`}>Products</a>
                    },

        ];

    if (category) {
        paths.push(
            {
                title: <a href={`/${language}/products/${category}`}>{category}</a>
            }
        )
    }

    if (sub_category) {
        paths.push({
            title: <a href={`/${language}/products/${sub_category}`}>{sub_category}</a>
        });
    }

    const onClickNav = (e) => {
        console.log('click', e);
        let path = e.keyPath.reverse().join('/')
        console.log(path)
        navigate(`/${language}/products/${path}`)
    }

    return (

            <Content
                style={{
                    padding: '0 48px',
                }}
            >
                <Breadcrumb
                    style={{
                        margin: '16px 0',
                    }}
                    items={paths}
                >

                </Breadcrumb>
                <Layout
                    style={{
                        padding: '24px 0',
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <Sider
                        style={{
                            background: '#9BB8CD',
                        }}
                        width={300}
                    >
                        <Menu
                            mode="inline"
                            onSelect={onClickNav}
                            defaultSelectedKeys={[sub_category]}
                            defaultOpenKeys={[category]}
                            style={{
                                height: '100%',
                            }}
                            items={categories}
                        />
                    </Sider>
                    <Content
                        style={{
                            padding: '0 24px',
                            // minHeight: 280,
                        }}
                    >
                        {content}
                    </Content>
                </Layout>
            </Content>

    );
};
