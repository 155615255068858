import { Breadcrumb, Layout, Menu, theme } from 'antd';
import AppBar from "./appbar";
import {useEffect} from "react";
import {FooterLinks} from "../../index/components/Footer";
import {HeaderMegaMenu} from "../../index/components/MegaHeader";
const { Header, Content, Footer } = Layout;
const Backbone = ({content}) => {
    useEffect(() => {
        document.title = '孔赟刀具-定制硬质合金铣刀钻头 kytools.tools- customize your solid carbide Custom End Mills and Cutters '
    },[]);
    return (
        <Layout className="layout" >
            <HeaderMegaMenu/>
            <Content
                style={{
                    // marginTop: 64,
                    padding: 20,
                    paddingBottom: 0,
                }}
            >
                <div
                    className="site-layout-content"
                    style={{
                        background: 'white',
                    }}
                >
                    {content}
                </div>
            </Content>
            <FooterLinks></FooterLinks>
        </Layout>
    );
};
export default Backbone;