import {Typography, Checkbox, Select, InputNumber, Button} from 'antd';
import {Content} from "antd/es/layout/layout";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
const { Paragraph, Text } = Typography;

export default function QuickGetPrice() {
    const [type, setType] = useState('Mills Cutter');
    const [diameter, setDiameter] = useState(0);
    const [length, setLength] = useState(0);
    const [price, setPrice] = useState(0);
    const [cuttingFee, setCuttingFee] = useState(0);
    const [coatingFee, setCoatingFee] = useState(0);
    const [materialFee, setMaterialFee] = useState(0);
    const [checkedCoating, setCheckedCoating] = useState(true);
    const { t, i18n } = useTranslation();
    const lng = useParams().language;


    const material_unit_price = 500;
    let margin_coef = 0.5;
    let usd_coef = 7;
    function calculatePrice() {
        let material = 0;
        material = (diameter + 0.3) * (diameter + 0.3) * 0.0011774 * (length / 100) * material_unit_price / margin_coef;
        setMaterialFee(material)
        let cuttingFee = 0;
        if (type === 'Mills Cutter') {
            cuttingFee = 4 * diameter/ margin_coef;
        }
        if (type === 'Drills') {
            cuttingFee = 8 * diameter/ margin_coef;
        }
        setCuttingFee(cuttingFee);
        let coatingFee = 0;
        if (checkedCoating) {
            coatingFee = 1.5 * diameter / margin_coef;
        }
        setCoatingFee(coatingFee);
        // console.log(`        `)
        // console.log(`diameter ${diameter}`)
        // console.log(`length ${length}`)
        // console.log(`material ${material}`)
        // console.log(`cuttingFee ${cuttingFee}`)
        // console.log(`coatingFee ${coatingFee}`)
        let total = material + cuttingFee + coatingFee;

        setPrice(total);
    }

    function onDiameterChange(value){
        setDiameter(value);
    }

    function onTypeChange(value){
        setType(value);
    }

    function onLengthChange(value){
        setLength(value);
    }

    function onCheckedChange(e){
        setCheckedCoating(e.target.checked);
    }

    return (
        <Content style={{
            maxWidth: "800px",
            minHeight: "60vh",
            margin: 'auto'  ,marginBottom: 50,
            paddingTop: 20,
            paddingLeft: 30,
            paddingRight: 30,
        }}>
            <div >
                <Typography.Title level={1} mark>{t("Quick Get Tool Price Online")}</Typography.Title>
                <Typography><strong>Introduction to Quick Quotation Online Calculator. </strong>{t('descriptionQuotation')}</Typography>

                <div style={{
                    paddingLeft: "20px"
                }}>
                    <Typography.Title level={2}>{t("Tools Type")}</Typography.Title>
                    <Select
                        defaultValue="Mills Cutter"
                        style={{ width: "60%" }}
                        onChange={onTypeChange}
                        options={[
                            { value: 'Mills Cutter', label: t('Milling Cutter') },
                            { value: 'Drills', label: t('Drills') },
                        ]}
                    />

                    <Typography.Title level={2}>{t('Diameter')}</Typography.Title>
                    <InputNumber
                        style={{ width: "60%" }}
                        defaultValue="0"
                        suffix="mm"
                        onChange={onDiameterChange}
                    />
                    <Typography.Title level={2}>{t('Overall Length')}</Typography.Title>
                    <InputNumber
                        style={{ width: "60%" }}
                        defaultValue="0"
                        suffix="mm"
                        onChange={onLengthChange}
                    />
                    <Typography.Title level={2}>{t("Coating or Not")}</Typography.Title>
                    <Checkbox checked={checkedCoating} onChange={onCheckedChange}>{t("Coated")}</Checkbox>
                    <Typography.Title level={2}></Typography.Title>
                    <Button onClick={calculatePrice} type="primary">{t("Get Price")}</Button>
                    <Typography.Title level={5}>{t("Quick Quote Price")}</Typography.Title>
                    <div style={{marginLeft:"12px"}}> <strong>{t("Material Fee")}</strong> ¥{Math.round(materialFee * 1000) / 1000} <strong>RMB</strong></div>
                    <div>+  <strong>{t("CNC Cutting Fee")}</strong> ¥{Math.round(cuttingFee * 1000) / 1000} <strong>RMB</strong></div>
                    <div>+  <strong>{t("Coating Fee")} </strong>¥{Math.round(coatingFee * 1000) / 1000} <strong>RMB</strong></div>
                    <br/>
                    <div>= ¥{Math.round(price * 1000) / 1000} <strong>RMB</strong></div>
                    <div>= ${Math.round(price * 1000 / usd_coef) / 1000} <strong>USD</strong></div>
                    <Paragraph type="secondary" style={{marginTop: "20%"}}>1.<sup>*</sup> {t("priceComment1")}</Paragraph>
                    <Paragraph type="secondary" >2.<sup>*</sup> {t("priceComment2")}</Paragraph>
                </div>

            </div>
        </Content>

    )
};