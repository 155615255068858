import Backbone from "../commons/layout";
import {useParams} from "react-router-dom";
import {findSubByCategory} from "../../utils/getData";
import {Col, Divider, Row} from "antd";
import aCard from "../commons/card";
import React, {useEffect, useState} from "react";
import {banners} from "../commons/banners";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {ContactUs} from "../../index/components/ContactUs/contactUs";

function Types() {
    let type = useParams().type
    const { t, i18n } = useTranslation();


    const lng = useParams().language;useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const resolvedData = await findSubByCategory(type);
            setData(resolvedData);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const [data, setData] = useState([]);

    console.log(data)
    return (
        <>
            <Helmet>
                <html lang={lng} />
                <title>{t('index-title')}</title>
                <meta name="description" content={t('index-description')}/>
                <meta name="keywords" content={t('index-keywords')}></meta>
            </Helmet>
            <div style={{margin: '2%'}}>
                <ContactUs/>
            </div>

            <div style={{margin: "2%"}}>

                <div>
                    {
                        data.map(
                            (category) => (
                                <div>
                                    <h1>{lng !== 'eng'?t(category.categoryName.replace(/[^a-zA-Z]/g, '').toLowerCase()):category.categoryName } </h1>
                                    <Row gutter={[16, 20]}>
                                        {category.subCategories.map(
                                            item => (
                                                <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
                                                    {aCard(item.image, item.title, item.descriptionEng,item.name, item.subCategory)}</Col>
                                            )
                                        )}
                                    </Row>
                                    <br></br>
                                    <Divider/>
                                </div>
                            )
                        )
                    }
                </div>

            </div>

        </>
    )
}
export default function Customize(){
    return (
        <Backbone content={Types()}></Backbone>
    )
};