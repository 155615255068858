import {HeaderMegaMenu} from "../MegaHeader";
import StandardProductsCards from "../../../products/StandardProductsCards";
import {FooterLinks} from "../Footer";
import {Container, createStyles, Overlay, rem} from "@mantine/core";
import {ContactUs} from "./contactUs";
import ancaBanner from "../../../../static/anca-banner.jpg";

const useStyles = createStyles((theme) => ({
    mills1: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-ban3_0.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    banners: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-com-bg.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    zoller: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/csm_01_Slider_2280x900_04_40_7ad48e6507.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    anca: {
        position: 'relative',
        backgroundImage: 'url('+String(ancaBanner)+')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    mills2: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-ban1_0.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    products: {
        position: 'relative',
        backgroundImage:
            'url(https://embed.widencdn.net/img/harveyperformance/qfzy5hdptq/exact/Harvey_MiniTaperedLg.png?position=c&crop=no&color=ffffff00&u=r5tz5r)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    mills3: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-ban4_0.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    mills4: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-ban2_0.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    mills5: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-ban4_0.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    containerCenter: {
        size: 1200,
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: rem(500),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },

    containerCenterShort: {
        size: 1200,
        paddingTop: rem(130),
        // paddingBottom: rem(130),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',
    },

    containerCenterShortWithBottom: {
        size: 1200,
        paddingTop: rem(130),
        paddingBottom: rem(130),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',
    },
    container: {
        size: 1200,
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: rem(500),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },
    zollerPad: {
        marginTop: "auto",
        marginBottom: "auto",
        paddingLeft: "5%",
        paddingRight: "5%",
        backgroundColor: "white",
        opacity: 0.86,
        // borderRadius: "50px",
    },
    zollerContainer: {
        marginLeft: "10%",
        marginRight: "10%",
        paddingTop: "2%",
        paddingBottom: "2%",
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        position: 'relative',
        [theme.fn.smallerThan('lg')]: {
            marginLeft: "10%",
            marginRight: "10%",
            height: rem(1000),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
        [theme.fn.largerThan('xl')]: {
            marginLeft: "15%",
            marginRight: "15%",
        }
    },


    highlight: {
        color: theme.colors[theme.primaryColor][4],
    },

    containerForm: {
        height: rem(700),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        // alignItems: 'flex-start',
        paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: rem(1000),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },

    containerFeatures: {
        height: rem(700),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'flex-start',
        paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: rem(1200),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },

    containerTall: {
        size: "xl",
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: rem(500),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },

    title: {
        color: theme.white,
        fontSize: rem(60),
        fontWeight: 900,
        lineHeight: 1.1,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(40),
            lineHeight: 1.2,
        },

        [theme.fn.smallerThan('xs')]: {
            fontSize: rem(28),
            lineHeight: 1.3,
        },
    },

    titleSmall: {
        color: theme.white,
        fontSize: rem(45),
        fontWeight: 900,
        lineHeight: 1.1,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(40),
            lineHeight: 1.2,
        },

        [theme.fn.smallerThan('xs')]: {
            fontSize: rem(28),
            lineHeight: 1.3,
        },
    },

    description: {
        color: theme.white,
        maxWidth: 600,

        [theme.fn.smallerThan('sm')]: {
            maxWidth: '100%',
            fontSize: theme.fontSizes.sm,
        },
    },

    control: {
        marginTop: `calc(${theme.spacing.xl} * 1.5)`,

        [theme.fn.smallerThan('sm')]: {
            width: '100%',
        },
    },
}));

export function ContactUsIndex(){
    const { classes } = useStyles();
    return (
        <>
            <HeaderMegaMenu></HeaderMegaMenu>
            <div className={classes.mills4} id="ContactUs">
                <Overlay
                    gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
                    opacity={1}
                    zIndex={0}
                />
                <Container className={classes.containerForm}>
                    <ContactUs/>
                </Container>
            </div>
            <FooterLinks></FooterLinks>
        </>
    )
}