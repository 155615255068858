import {HeaderMegaMenu} from "../index/components/MegaHeader";
import {FooterLinks} from "../index/components/Footer";
import MantineCarousel from "./carousel";
import {Helmet} from "react-helmet";
import React, {useEffect, useState} from "react";
import {findAllGallery, findSubByCategory} from "../utils/getData";
import {Category} from "tabler-icons-react";
import {Col, Divider, Row} from "antd";
import aCard, {galleryCard} from "../cutomize/commons/card";
const mockData = [
    {
        imageLink: "https://cdn0.grizzly.com/pics/jpeg288/h/h3445-d8c42372735f3c95d77ed4a53526e636.jpg"
    },
    {
        imageLink: "https://cdn0.grizzly.com/pics/jpeg288/h/h7643-69f072245a26330b1b6d22ea0133affb.jpg"
    },{
        imageLink: "https://cdn0.grizzly.com/pics/jpeg288/g/g9882-3acb67d5aaf0830c1bacb88b50ad4f58.jpg"
    },{
        imageLink: "https://cdn0.grizzly.com/pics/jpeg288/h/h7629-ecdcbb69496f35f122f2eb4733fdc574.jpg"
    }
]
export default function GalleryIndex() {
    const [data, setData] = useState({});

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const resolvedData = await findAllGallery();
            let category2item = {}
            for (let item of resolvedData) {
                if (!category2item[item['category']]) {
                    category2item[item['category']] = [];
                }
                category2item[item['category']].push(item);
            }
            setData(category2item);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return <>
        <Helmet>
            <link rel="canonical" href=""/>
        </Helmet>
        <HeaderMegaMenu></HeaderMegaMenu>
        <div style={{margin: "1%", background: "#F2F1EB", padding: "1%"}}>

        {
            Object.keys(data).map(category => {
                return (<>
                    <div>
                        <h1>{category} </h1>
                        <Row gutter={[16, 20]}>
                            {data[category].map(
                                item => (
                                    <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
                                        {galleryCard(item.mainPictureLink, item.description, item.name)}</Col>
                                )
                            )}
                        </Row>
                        <br></br>
                        <Divider/>
                    </div>
                </>)
            })
        }
        </div>


        <FooterLinks></FooterLinks>
    </>
};