import {
    createStyles,
    Header,
    HoverCard,
    Group,
    Button,
    UnstyledButton,
    Text,
    SimpleGrid,
    ThemeIcon,
    Anchor,
    Divider,
    Center,
    Box,
    Burger,
    Drawer,
    Collapse,
    ScrollArea,
    rem,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    IconNotification,
    IconCode,
    IconBook,
    IconChartPie3,
    IconFingerprint,
    IconCoin,
    IconChevronDown,
} from '@tabler/icons-react';
import {useTranslation} from "react-i18next";
import logo from "../../../static/ico.jpeg";
import {World, ShoppingCart} from "tabler-icons-react";
import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import QuotationModal from "./QuotationModal";

const useStyles = createStyles((theme) => ({
    link: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontWeight: 500,
        fontSize: theme.fontSizes.sm,

        [theme.fn.smallerThan('sm')]: {
            height: rem(42),
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },

        ...theme.fn.hover({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        }),
    },

    subLink: {
        width: '100%',
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        borderRadius: theme.radius.md,

        ...theme.fn.hover({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        }),

        '&:active': theme.activeStyles,
    },

    dropdownFooter: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        margin: `calc(${theme.spacing.md} * -1)`,
        marginTop: theme.spacing.sm,
        padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
        paddingBottom: theme.spacing.xl,
        borderTop: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
        }`,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },
}));


export function HeaderMegaMenu() {
    const { t, i18n } = useTranslation();

    const lng = useParams().language;
    const getToggleLng = (lng) => {
        if (lng == 'zh') {
            return '/eng';
        } else {
            return '/zh'
        }
    }

    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
    const [isShowQuotationModal, setIsShowQuotationModal] = useState(false);
    const { classes, theme } = useStyles();

    const [data, setData] = useState();

    const showQuotationModal = () => {
        setIsShowQuotationModal(true);
    };


    useEffect(() => {
        setData([
            {
                'title': 'Square End Mills',
                'link': 'Solid Carbide End Mills/Square End Mills 2 Flute',
                'imageLink': 'https://embed.widencdn.net/img/harveyperformance/7hi2ezqn0e/exact/Harvey_MiniSquare_Sm.png?position=c&crop=no&color=ffffff00&u=r5tz5r',
                'description': 'KyTools offering of square profile End Mills includes Stub & Standard, Long Reach, Reduced Shank, Tapered, and Long Flute designs, among others. Choose from diameter sizes as miniature as .001" and use your new tool in your machine tomorrow morning.',
            },
            {
                'title': 'Ball End Mills',
                'link': 'Solid Carbide End Mills/Ball End Mills 2 Flute',
                'imageLink': 'https://embed.widencdn.net/img/harveyperformance/rqyv5smzba/exact/Harvey_MiniBall_Sm.png?position=c&crop=no&color=ffffff00&u=r5tz5r',
                'description': 'Create complex three-dimensional contours and a smooth part finish with this offering of ball profile Miniature End Mills, fully stocked in multiple flute lengths, reaches up to 25x cutter diamater, and sizes as miniature as .002" diameter.',
            },
            {
                'title': 'Corner Radius Mills',
                'link': 'Solid Carbide End Mills/Corner Radius End Mills 4 Flute',
                'imageLink': 'https://embed.widencdn.net/img/harveyperformance/k7pg4k72yc/exact/Harvey_CornerRadius_Sm.png?position=c&crop=no&color=ffffff00&u=r5tz5r',
                'description': 'KYTools Corner Radius Miniature End Mills are stocked in a variety of radii for fast material removal and increased tool strength. Enjoy the excellent rigidity and wear resistance these tools provide in even the toughest of materials.',
            },
            {
                'title': 'Roughing End Mills',
                'link': 'Roughing End Mills',
                'imageLink': 'https://embed.widencdn.net/img/harveyperformance/562iqbsjfb/exact/Harvey_MaterialSP_Composites_Chipbreaker_Sm.png?position=c&color=ffffff00&quality=80&u=rkinig',
                'description': 'This offering includes optimized geometry with chipbreakers, designed to efficiently shear fibers and shorten chips for improved chip removal.',
            },
            {
                'title': 'Spotting Drills',
                'link': 'Spotting Drill',
                'imageLink': 'https://embed.widencdn.net/img/harveyperformance/03x0ybdayq/exact/Harvey_Holemaking_SpottingDrills_Sm.png?position=c&color=ffffff00&quality=80&u=rkinig',
                'description': 'With a thinned web that reduces walking and a self-centering point geometry, this offering of fully stocked Spotting Drills is optimized to deliver precision accuracy.',
            },
            {
                'title': 'Long Neck (Reach)',
                'link': 'Long Neck End Mill',
                'imageLink': 'https://embed.widencdn.net/img/harveyperformance/dr5snseyqd/exact/Harvey_MiniSquare_LongReach_Sm.png?position=c&color=ffffff00&quality=80&u=rkinig',
                'description': 'This KYTools offering long reach End Mills is optimized to provide rigidity in deep pocketing applications.',
            },
        ])
    }, []);
    const links = data ? data.map((item) => (
        <UnstyledButton className={classes.subLink} key={item.title} component="a" href={`/${lng}/products/${item.link}`}>
            <Group noWrap align="flex-start">
                <ThemeIcon size={34} variant="default" radius="md">
                    {/*<item.icon size={rem(22)} color={theme.fn.primaryColor()} />*/}
                    <img  height={rem(500)} src={item.imageLink} />
                </ThemeIcon>
                <div>
                    <Text size="sm" fw={500}>
                        {item.title}
                    </Text>
                    <Text size="xs" color="dimmed">
                        {item.description}
                    </Text>
                </div>
            </Group>
        </UnstyledButton>
    )):<></>;

    return (
        <Box >
            <Header height={60} px="md">
                <Group position="apart" sx={{ height: '100%' }}>
                    <img alt = 'logo' style={{'width':'170px'}} src={String(logo)} />

                    <Group sx={{height: '100%'}} spacing={0} className={classes.hiddenMobile}>
                        <a href={"/" + lng} className={classes.link}>
                            {t("Home")}
                        </a>
                        <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
                            <HoverCard.Target>
                                <a href="#" className={classes.link}>
                                    <Center inline>
                                        <Box component="span" mr={5}>
                                            {t("Products")}
                                        </Box>
                                        <IconChevronDown size={16} color={theme.fn.primaryColor()}/>
                                    </Center>
                                </a>
                            </HoverCard.Target>

                            <HoverCard.Dropdown sx={{overflow: 'hidden'}}>
                                <Group position="apart" px="md">
                                    <Text fw={500}>
                                        {t("Products")}
                                    </Text>
                                    <Link to={`/${lng}/products`}>
                                        <Anchor href="#" fz="xs">
                                            {t("View all")}
                                        </Anchor>
                                    </Link>
                                </Group>

                                <Divider
                                    my="sm"
                                    mx="-md"
                                    color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}
                                />

                                <SimpleGrid cols={2} spacing={0}>
                                    {links}
                                </SimpleGrid>

                                <div className={classes.dropdownFooter}>
                                    <Group position="apart">
                                        <div>
                                            <Text fw={500} fz="sm">
                                                {t("Customize")}
                                            </Text>
                                            <Text size="xs" color="dimmed">
                                                {t("CustomizeDetail")}
                                            </Text>
                                        </div>
                                        <Link to={`/${lng}/customize`}>
                                            <Button variant="default">{t("Start Customize")}</Button>
                                        </Link>
                                    </Group>
                                </div>
                            </HoverCard.Dropdown>
                        </HoverCard>
                        <a href={"/" + lng + "/getPrice"} className={classes.link}>
                            {t("Get Price Online")}
                        </a>
                        <a href={"/" + lng + "/customize"} className={classes.link}>
                            {t("customize")}
                        </a>
                        <a href={"/" + lng + "/contact"} className={classes.link}>
                            {t("Contact Us")}
                        </a>
                        <a href={"/" + lng + "/gallery"} className={classes.link}>
                            {t("Gallery")}
                        </a>

                        <a href={"/" + lng + "/blog"} className={classes.link}>
                            {t("Blog")}
                        </a>

                    </Group>

                    <Group className={classes.hiddenMobile}>
                        <Button onClick={showQuotationModal}><ShoppingCart/> {t("order")}</Button>
                        {QuotationModal(isShowQuotationModal, setIsShowQuotationModal)}
                        <Button variant="default" component="a" href={getToggleLng(lng)}><World/> {t("language")}</Button>

                    </Group>

                    <Burger opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
                </Group>
            </Header>

            <Drawer
                opened={drawerOpened}
                onClose={closeDrawer}
                size="100%"
                padding="md"
                title={t("Navigation")}
                className={classes.hiddenDesktop}
                zIndex={1000000}
            >
                <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
                    <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

                    <a href={"/"+lng} className={classes.link}>
                        {t("Home")}
                    </a>
                    <UnstyledButton className={classes.link} onClick={toggleLinks}>
                        <Center inline>
                            <Box component="span" mr={5}>
                                {t("Products")}
                            </Box>
                            <IconChevronDown size={16} color={theme.fn.primaryColor()} />
                        </Center>
                    </UnstyledButton>
                    <Collapse in={linksOpened}>{links}</Collapse>
                    <a href={"/"+lng+"/getPrice"} className={classes.link}>
                        {t("Get Price Online")}
                    </a>
                    <a href={"/"+lng+"/customize"} className={classes.link}>
                        {t("customize")}
                    </a>
                    <a href={"/"+lng+"/contact"} className={classes.link}>
                        {t("Contact Us")}
                    </a>
                    <a href={"/"+lng+"/gallery"} className={classes.link}>
                        {t("Gallery")}
                    </a>


                    <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

                    <Group position="center" grow pb="xl" px="md">
                        <Button>{t("order")}</Button>
                    </Group>
                </ScrollArea>
            </Drawer>
        </Box>
    );
}