import {createStyles, Image, Accordion, Grid, Col, Container, Title, rem} from '@mantine/core';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useEffect} from "react";

const useStyles = createStyles((theme) => ({
    wrapper: {
        paddingTop: `calc(${theme.spacing.xl} * 8)`,
        paddingBottom: `calc(${theme.spacing.xl} * 8)`,
    },

    title: {
        marginBottom: theme.spacing.md,
        paddingLeft: theme.spacing.md,
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    item: {
        fontSize: theme.fontSizes.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
    },
}));

const placeholder =
    'It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.';

export function FaqWithImage() {
    const { classes } = useStyles();
    const { t, i18n } = useTranslation();


    return (
        <div className={classes.wrapper}>
            <Container size="lg">
                <Grid id="faq-grid" gutter={50}>
                    <Col span={12} md={6}>
                        <Image src='https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/svgs/image.b0c2306b.svg' alt="Frequently Asked Questions" />
                    </Col>
                    <Col span={12} md={6}>
                        <Title order={2} ta="left" className={classes.title}>
                            {t("FAQTitle")}
                        </Title>

                        <Accordion chevronPosition="right" defaultValue="reset-password" variant="separated">
                            <Accordion.Item className={classes.item} value="reset-password">
                                <Accordion.Control>{t("FAQ-q1")}</Accordion.Control>
                                <Accordion.Panel>{t("FAQ-a1")}</Accordion.Panel>
                            </Accordion.Item>

                            <Accordion.Item className={classes.item} value="another-account">
                                <Accordion.Control>{t("FAQ-q2")}</Accordion.Control>
                                <Accordion.Panel>{t("FAQ-a2")}</Accordion.Panel>
                            </Accordion.Item>

                            <Accordion.Item className={classes.item} value="newsletter">
                                <Accordion.Control>{t("FAQ-q3")}</Accordion.Control>
                                <Accordion.Panel>{t("FAQ-a3")}</Accordion.Panel>
                            </Accordion.Item>

                            <Accordion.Item className={classes.item} value="credit-card">
                                <Accordion.Control>
                                    {t("FAQ-q4")}
                                </Accordion.Control>
                                <Accordion.Panel>{t("FAQ-a4")}</Accordion.Panel>
                            </Accordion.Item>

                            <Accordion.Item className={classes.item} value="payment">
                                <Accordion.Control>{t("FAQ-q5")}</Accordion.Control>
                                <Accordion.Panel>{t("FAQ-a5")}</Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Grid>
            </Container>
        </div>
    );
}