import {Timeline, Text, Title, createStyles, rem, Container} from '@mantine/core';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useEffect} from "react";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(34),
        fontWeight: 900,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
        },
    },

    description: {
        maxWidth: 600,
        margin: 'auto',

        '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.fn.primaryColor(),
            width: rem(45),
            height: rem(2),
            marginTop: theme.spacing.sm,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },

    card: {
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
        }`,
    },

    cardTitle: {
        '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.fn.primaryColor(),
            width: rem(45),
            height: rem(2),
            marginTop: theme.spacing.sm,
        },
    },
}));
export function TimelineComponent() {
    const { classes, theme } = useStyles();

    const { t, i18n } = useTranslation();

    return (
        <Container size="lg" py="xl">
            <Title order={2} className={classes.title} ta="center" mt="sm" color={theme.white}>
                {t("timeLineTitle")}
            </Title>

            <Text c="gray.0" className={classes.description} ta="center" mt="md" >
                {t("timeLineDescription")}
            </Text>

            <Timeline active={3} bulletSize={18} lineWidth={5} style={{"marginTop": "10px"}}>
                <Timeline.Item  title={t("timeLineItem1Title")} style={{color: "white"}}>
                    <Text color="gray.5" size="sm">{t("timeLineItem1Detail")}</Text>
                    <Text size="xs" mt={4}>{t("timeLineItem1Time")}</Text>
                </Timeline.Item>

                <Timeline.Item  title={t("timeLineItem2Title")} style={{color: "white"}}>
                    <Text color="dimmed" size="sm">{t("timeLineItem2Detail")}</Text>
                    <Text size="xs" mt={4}>{t("timeLineItem2Time")}</Text>
                </Timeline.Item>

                <Timeline.Item  title={t("timeLineItem3Title")} style={{color: "white"}}>
                    <Text color="dimmed" size="sm">{t("timeLineItem3Detail")}</Text>
                    <Text size="xs" mt={4}>{t("timeLineItem3Time")}</Text>
                </Timeline.Item>

                <Timeline.Item  title={t("timeLineItem4Title")} style={{color: "white"}}>
                    <Text color="dimmed" size="sm">{t("timeLineItem4Detail")}</Text>
                    <Text size="xs" mt={4}>{t("timeLineItem4Time")}</Text>
                </Timeline.Item>

                <Timeline.Item  title={t("timeLineItem5Title")} style={{color: "white"}}>
                    <Text color="dimmed" size="sm">{t("timeLineItem5Detail")}</Text>
                    <Text size="xs" mt={4}>{t("timeLineItem5Time")}</Text>
                </Timeline.Item>

                <Timeline.Item  title={t("timeLineItem6Title")} style={{color: "white"}}>
                    <Text color="dimmed" size="sm">{t("timeLineItem6Detail")}</Text>
                    <Text size="xs" mt={4}>{t("timeLineItem6Time")}</Text>
                </Timeline.Item>

            </Timeline>

        </Container>
    );

}