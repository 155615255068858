const currentHost = `${window.location.protocol}//${window.location.hostname}`;
let baseUrl = "http://kytools.tech";

// let baseUrl = currentHost;
// if (window.location.href.indexOf("3000") !== -1) {
//     baseUrl += ":8080"
// }
export const eng2zh = {'standard': '标准型',
    'longreach': '长刀杆型',
    'tapered': '锥度型',
    'backdeburringmill': '背倒角铣刀',
    'chamfercutters': '倒角刀',
    'concaveradiusendmills': '凹弧半径铣刀',
    'cornerroundingendmills': '圆角刀',
    'doubleangleshankcutters': '双角柄切削刀',
    'dovetailcutters': '榫槽刀',
    'drillmills': '钻铣复合刀',
    'engravingcutters': '雕刻刀',
    'keyseatcutters': '键槽刀',
    'pcddiamondendmills': 'PCD金刚石铣刀',
    'saws': '锯',
    'undercuttingendmills': '倒角立铣刀',
    'counterbores': '槽钻',
    'drills': '钻头',
    'drillcountersinks': '钻孔和倒钻中心',
    'reamers': '铰刀',
    'threadmills': '螺纹刀',
    'deburring': '去毛刺',
    'composite': '复合材料',
    'hardmilling': '硬铣削',
    'imperial': '英制',
    'metric': '公制',
    'plasticcutters': '塑料刀具',
    'reducedshank': '缩径',
    'stepped': '阶梯',
    'variablehelix': '变螺旋角',
    'holemakingthreading': '钻头 & 铰刀',
    'endmills': '铣刀',
    'specialtyprofiles': '成型刀',
    'etching': '激光刻字',
    'weldonflat': 'Weldon 平面',
    'shankdiameterd': '柄径',
    'overalllengthl': '总长度',
    'neckdiameterd': '颈径',
    'lengthofcutl': '刃长',
    'cutterdiameterd': '刃径',
    'overallreachl':'颈长',
    'cornerradiusr': 'R角',

}

export async function findAllGallery() {

    try {
        const params = new URLSearchParams();

        const response = await fetch(`${baseUrl}/api/gallery`);
        const jsonData = await response.json();
        return jsonData
    } catch (error) {
        console.error('Error:', error);
        return undefined;
    }

    return []
}

export async function findStandProductByCategory(category, sub_category) {

    try {
        const params = new URLSearchParams();
        if (category) {
            params.append('category', category);
        }
        if (sub_category) {
            params.append('subCategory', sub_category);
        }
        const response = await fetch(`${baseUrl}/api/standardProduct?${params}`);
        const jsonData = await response.json();
        return jsonData
    } catch (error) {
        console.error('Error:', error);
        return undefined;
    }

    return []
}

export async function findStandProductByID(id) {

    try {
        const response = await fetch(`${baseUrl}/api/standardProduct/${id}`);
        const jsonData = await response.json();
        return jsonData
    } catch (error) {
        console.error('Error:', error);
        return undefined;
    }

    return undefined;
}
export async function postBuild(values) {
    try {
        const response = await fetch(`${baseUrl}/api/build`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        })
        return response
    } catch (error) {
        console.error('Error:', error);
        return undefined;
    }

    return 'Error';
}

export async function postQuotation(values) {
    try {
        const response = await fetch(`${baseUrl}/api/quotation`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        })
        return response
    } catch (error) {
        console.error('Error:', error);
        return undefined;
    }

    return 'Error';
}

export async function findSubByCategory(categoryName) {
    if (categoryName === undefined) {
        try {
            const response = await fetch(`${baseUrl}/api/categories/sub/all`);
            const jsonData = await response.json();
            return jsonData
        } catch (error) {
            console.error('Error:', error);
            return undefined;
        }
    } else {
        try {
            const params = new URLSearchParams({
                subCategory: categoryName,
            });
            const response = await fetch(`${baseUrl}/api/categories/sub?${params}`)
            const jsonData = await response.json();
            return jsonData
        } catch (error) {
            console.error('Error:', error);
            return undefined;
        }
    }

    return []
}

export async function findSkuByCategory(type) {

    try {
        const params = new URLSearchParams({
            subCategory: type,
        });
        const response = await fetch(`${baseUrl}/api/skus/type?${params}`)
        const jsonData = await response.json();
        return jsonData
    } catch (error) {
        console.error('Error:', error);
        return undefined;
    }

    return []
}

export async function findSkuById(id) {
    try {
        const response = await fetch(`${baseUrl}/api/skus/${id}`)
        const jsonData = await response.json();
        return jsonData
    } catch (error) {
        console.error('Error:', error);
        return undefined;
    }
}

export async function getPostList() {
    try {
        const response = await fetch(`${baseUrl}/api/blogposts/list`)
        const jsonData = await response.json();
        return jsonData
    } catch (error) {
        console.error('Error:', error);
        return undefined;
    }
}


export async function getPost(id) {
    try {
        const response = await fetch(`${baseUrl}/api/blogposts/${id}`)
        const jsonData = await response.json();
        return jsonData
    } catch (error) {
        console.error('Error:', error);
        return undefined;
    }
}

