import {Form, Input, Select, Switch, Tooltip} from "antd";
import React from "react";
import {QuestionCircleFilled} from "@ant-design/icons";
import {eng2zh} from "../../utils/getData";
const { Option } = Select;
export const renderTooltip = (text) => (
    <Tooltip title={text}>
            <span style={{ cursor: 'help' }}>
                <QuestionCircleFilled />
            </span>
    </Tooltip>
);
export function NumberForm(form){
    let zh = eng2zh[form['name'].replace(/[^a-zA-Z]/g, '').toLowerCase()] ? eng2zh[form['name'].replace(/[^a-zA-Z]/g, '').toLowerCase()] + ' / ': "";
    return <>
        <Form.Item
            label={zh+form['name']}
            name={form['name']}
            rules={[{ required: false, message: 'Please enter '+form['name'] }]}
        >
            <Input addonAfter={"mm"} type={"number"}/>
        </Form.Item>
    </>
}


export function checkBoxForm(form) {
    let zh = eng2zh[form['name'].replace(/[^a-zA-Z]/g, '').toLowerCase()] ? eng2zh[form['name'].replace(/[^a-zA-Z]/g, '').toLowerCase()] + ' / ': "";
    return (
        <Form.Item
            label={zh+form['name']}
            name={form['name']}
        >
            <Switch/>
        </Form.Item>
    )
}



export function Flutes(isShow) {
    return isShow? (
        <Form.Item
            label="齿数 / Flutes"
            name="flutes"
            rules={[{ required: false, message: '请选择齿数!' }]}
        >
            <Select>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>4</Option>
                <Option value={6}>6</Option>
                <Option value={8}>8</Option>
            </Select>
        </Form.Item>
    ):(<></>)
}


export function Helix(isShow) {
    return isShow?(
        <Form.Item
            label="螺旋角度 / Helix"
            name="helix"
            rules={[{ required: false, message: '请选择螺旋角度!' }]}
        >
            <Input addonAfter={"°"} type={"number"}/>
        </Form.Item>
    ):(<></>)
}




export function Coatings(isShow) {
    return isShow?(
        <Form.Item
            label={(<span>涂层 / Coating {renderTooltip(
                "钛氮化物 (TiN)：常用于高速钢刀具和钻头上，能提供良好的磨损抗性和润滑性，从而延长工具使用寿命。\n\n" +
                "钛碳氮化物 (TiCN)：类似于TiN，但硬度更高，适用于更高的切削速度和更坚硬的材料。\n\n" +
                "钛铝氮化物 (TiAlN)：适用于切削高温合金，提供良好的磨损抗性和热稳定性。\n\n" +
                "类金刚石碳（DLC）：适用于切削如碳纤维和复合材料等耐磨材料。它提供优异的耐磨性能和减少摩擦力。\n\n" +
                "铝钛氮化物（AlTiN）：具有高硬度，适用于切削硬质材料。它还提供良好的耐磨性和热稳定性。\n\n" +
                "氮化锆 (ZrN)：常用于切削铝和铜等有色金属。它提供良好的耐磨性能和防止边缘累积。\n\n" +
                "氮化铬 (CrN)：适用于切削耐磨材料，提供良好的耐磨性能和改善的润滑性。")}</span>)}
            name="coating"
            rules={[{ required: false, message: '请选择涂层!' }]}
        >
            <Select >
                <Option value="No Specific">厂商决定</Option>
                <Option value="TiN">TiN</Option>
                <Option value="TiCN">TiCN</Option>
                <Option value="TiAlN">TiAlN</Option>
                <Option value="DLC">DLC</Option>
                <Option value="AlTiN">AlTiN</Option>
                <Option value="ZrN">ZrN</Option>
                <Option value="CrN">CrN</Option>
            </Select>
        </Form.Item>
    ):(<></>)
}

export function WorkpieceMaterial(isShow) {
    return isShow? (
        <Form.Item
            label="工件材料 / Workpiece Material"
            name="workpieceMaterial"
            rules={[{ required: false, message: '请选择工件材料!' }]}
        >
            <Select >
                <Option value="钢 / Steel">钢 / Steel</Option>
                <Option value="不锈钢 / Stainless Steel">不锈钢 / Stainless Steel</Option>
                <Option value="铜 / Copper">铜 / Copper</Option>
                <Option value="铝 / Aluminum">铝 / Aluminum</Option>
                <Option value="塑料 / Plastic">塑料 / Plastic</Option>
            </Select>
        </Form.Item>
    ):(<></>)
}
export function CutDirection(isShow) {
    return isShow?(
        <Form.Item
            label={(<span>切削方向 / Cut Direction {renderTooltip("在顺铣中，切削刀具沿旋转方向进给。只要机床、夹具和工件允许，顺铣始终为首选方法。\n\n 在逆铣中，切削刀具的进给方向与其旋转方向相反。")}</span>)}
            name="cutDirection"
            rules={[{ required: false, message: '请选择切削方向!' }]}
        >
            <Select>
                <Option value="正铣 / Upcut">正铣 / Upcut</Option>
                <Option value="反铣 / Downcut">反铣 / Downcut</Option>
            </Select>
        </Form.Item>
    ):(<></>)
}

export function Quantity(isShow) {
    return isShow?(
        <div>
            <h3>数量 / Quantity</h3>
            <Form.Item
                label="数量 / Quantity"
                name="quantity"
            >
                <Input addonAfter={"支 / pic"} type={"number"} placeholder={10}/>
            </Form.Item>
        </div>
    ):(<></>)
}
export function Contacts(isShow) {
    return isShow? (
        <div>

            <h3>联系信息 / Contact Information</h3>
            <Form.Item
                label="姓名 / Name"
                name="name"
                rules={[{ required: false, message: '请输入您的姓名!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="微信 / Wechat ID"
                name="wechat"
                rules={[{ required: false, message: '请输入您的电话号码!' }]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="电子邮件 / Email"
                name="email"
                rules={[
                    { required: false, message: '请输入您的电子邮件!' },
                    {
                        // type: 'email',
                        message: '请输入有效的电子邮件!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="电话号码 / Phone Number"
                name="phone"
                rules={[{ required: false, message: '请输入您的电话号码!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="地址 / Address"
                name="address"
                rules={[{ required: false, message: '请输入您的地址 / Please enter your address' }]}
            >
                <Input.TextArea rows={4} placeholder="请输入您的地址 / Please enter your address" />
            </Form.Item>


        </div>
    ):(<></>)
}

