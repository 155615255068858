import {createStyles, Overlay, Container, Title, Button, Text, rem, ThemeIcon, List, Group, Image} from '@mantine/core';
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {ContactUs} from "./components/ContactUs/contactUs";
import {FeaturesCards} from "./components/FeaturesCards";
import {CommentCards} from "./components/CommentCards";
import {FooterLinks} from "./components/Footer";
import {FaqWithImage} from "./components/Faq";
import {ANCA, Zoller} from "./components/HeroBullets";
import {Award, ThumbUp, } from "tabler-icons-react";
import {EngMap, ZhMap} from "./components/GoogleMap";
import {TimelineComponent} from "./components/Timeline";
import {HeaderMegaMenu} from "./components/MegaHeader";
import ProductsCards from "../products/ProductsCards";
import {notification} from "antd";
import QuotationModal from "./components/QuotationModal";
import ancaLogo from "../../static/anca-logo.png";
import aliexpressLong from "../../static/aliexpress_logo_icon.png";
import {IconArrowRight, IconDownload} from "@tabler/icons-react";


const ancaBanner = require('../../static/anca-banner.jpg')
const useStyles = createStyles((theme) => ({
    mills1: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-ban3_0.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    banners: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-com-bg.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    zoller: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/csm_01_Slider_2280x900_04_40_7ad48e6507.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    anca: {
        position: 'relative',
        backgroundImage: 'url('+String(ancaBanner)+')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    mills2: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-ban1_0.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    products: {
        position: 'relative',
        backgroundImage:
            'url(https://embed.widencdn.net/img/harveyperformance/qfzy5hdptq/exact/Harvey_MiniTaperedLg.png?position=c&crop=no&color=ffffff00&u=r5tz5r)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    mills3: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-ban4_0.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    mills4: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-ban2_0.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    mills5: {
        position: 'relative',
        backgroundImage:
            'url(https://kytools-object.oss-cn-shanghai.aliyuncs.com/kytools-object/static-images/category/index-ban4_0.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    containerCenter: {
        size: 1200,
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: rem(500),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },

    containerCenterShort: {
        size: 1200,
        paddingTop: rem(130),
        // paddingBottom: rem(130),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',
    },

    containerCenterShortWithBottom: {
        size: 1200,
        paddingTop: rem(130),
        paddingBottom: rem(130),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',
    },
    container: {
        size: 1200,
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: rem(500),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },
    zollerPad: {
        marginTop: "auto",
        marginBottom: "auto",
        paddingLeft: "5%",
        paddingRight: "5%",
        backgroundColor: "white",
        opacity: 0.86,
        // borderRadius: "50px",
    },
    zollerContainer: {
        marginLeft: "10%",
        marginRight: "10%",
        paddingTop: "2%",
        paddingBottom: "2%",
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        position: 'relative',
        [theme.fn.smallerThan('lg')]: {
            marginLeft: "10%",
            marginRight: "10%",
            height: rem(1000),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
        [theme.fn.largerThan('xl')]: {
            marginLeft: "15%",
            marginRight: "15%",
        }
    },


    highlight: {
        color: theme.colors[theme.primaryColor][4],
    },

    containerForm: {
        height: rem(700),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        // alignItems: 'flex-start',
        paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: rem(1000),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },

    containerFeatures: {
        height: rem(700),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'flex-start',
        paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: rem(1200),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },

    containerTall: {
        size: "xl",
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
        zIndex: 1,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            height: rem(500),
            paddingBottom: `calc(${theme.spacing.xl} * 3)`,
        },
    },

    title: {
        color: theme.white,
        fontSize: rem(60),
        fontWeight: 900,
        lineHeight: 1.1,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(40),
            lineHeight: 1.2,
        },

        [theme.fn.smallerThan('xs')]: {
            fontSize: rem(28),
            lineHeight: 1.3,
        },
    },

    titleSmall: {
        color: theme.white,
        fontSize: rem(45),
        fontWeight: 900,
        lineHeight: 1.1,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(40),
            lineHeight: 1.2,
        },

        [theme.fn.smallerThan('xs')]: {
            fontSize: rem(28),
            lineHeight: 1.3,
        },
    },

    description: {
        color: theme.white,
        maxWidth: 600,

        [theme.fn.smallerThan('sm')]: {
            maxWidth: '100%',
            fontSize: theme.fontSizes.sm,
        },
    },

    control: {
        marginTop: `calc(${theme.spacing.xl} * 1.5)`,

        [theme.fn.smallerThan('sm')]: {
            width: '100%',
        },
    },
}));

export function IndexPage() {
    const { classes } = useStyles();
    const { t, i18n } = useTranslation();
    const [isShowQuotationModal, setIsShowQuotationModal] = useState(false);
    const showQuotationModal = () => {
        setIsShowQuotationModal(true);
    };
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const lng = useParams().language;
    const aliexpressLong = require('../../static/aliexpress_logo_icon.png')

    useEffect(()=>{
        changeLanguage(lng)
        if ((browserLng.indexOf('zh') !== -1 && lng !== 'zh')) {
            openNotification();
        }
    });

    const browserLng = window.navigator.language;
    let navigate = useNavigate()
    // if (browserLng.indexOf('zh') !== -1 && lng !== 'zh') {
    //     navigate("/zh")
    // }

    const [api, contextHolder] = notification.useNotification();

    const openNotification = () => {
        api.open({
            message: '访问孔赟刀具中文网站',
            description:
                (<a href="/zh">点击直达</a>),
            // duration: 0,
        });
    };

    return (
        <>
            {/*{*/}
            {/*    (browserLng.indexOf('zh') !== -1 && lng !== 'zh')&&*/}
            {/*    <Notification  color="blue" title="访问孔赟刀具中文网站">*/}
            {/*        <a href="/zh">点击直达</a>*/}
            {/*    </Notification>*/}
            {/*}*/}

            <Helmet>
                <html lang={lng} />
                <title>{t('index-title')}</title>
                <meta name="description" content={t('index-description')}/>
                <meta name="keywords" content={t('index-keywords')}></meta>
                <link rel="canonical" href=""/>
            </Helmet>
            <HeaderMegaMenu/>
            {QuotationModal(isShowQuotationModal, setIsShowQuotationModal)}



            <div className={classes.mills1}>
                <Overlay
                    gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
                    opacity={1}
                    zIndex={0}
                />
                <Container className={classes.container}>
                    <Title className={classes.titleSmall}>{t('sloganTitle1')}</Title>
                    <Title order={2} size="h4" textWrap="balance" className={classes.description}  mt="xl">
                        {t('sloganDetail1')}
                    </Title>
                    <Group mt={30}>
                        <Button variant="gradient" size="xl" radius="xl" className={classes.control} onClick={showQuotationModal}>
                            {t('order')}
                        </Button>

                        <Button variant="gradient" size="xl" radius="xl" className={classes.control}
                                component="a" href={"https://www.ebay.com/usr/kytools"}
                                target="_blank"
                                leftSection={
                                    <Image src={String(aliexpressLong)} />
                                }
                                gradient={{ from: 'pink', to: 'red', deg: 90 }}
                                rightIcon={<IconArrowRight size={14} />}
                        >
                            {t('Buy@eBay')}
                        </Button>

                    </Group>
                </Container>
            </div>

            <div className={classes.mills1}>
                <Overlay
                    gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
                    opacity={1}
                    zIndex={0}
                />
                <Container className={classes.container}>
                    <Title className={classes.titleSmall}>{t('sloganTitle2')}</Title>
                    <Title order={2} size="h4" textWrap="balance" className={classes.description} mt="xl">
                        {t('sloganDetail2')}
                    </Title>
                    <Group mt={30}>

                    <Button variant="gradient" size="xl" radius="xl" className={classes.control} onClick={showQuotationModal}>
                        {t('order')}
                    </Button>
                        <Button variant="gradient" size="xl" radius="xl" className={classes.control}
                                component="a" href={"https://www.ebay.com/usr/kytools"}
                                target="_blank"
                                leftSection={
                                    <Image src={String(aliexpressLong)} />
                                }
                                gradient={{ from: 'pink', to: 'red', deg: 90 }}
                                rightIcon={<IconArrowRight size={14} />}
                        >
                            {t('Buy@eBay')}
                        </Button>
                    </Group>
                </Container>
            </div>

            <div className={classes.mills1}>
                <Overlay
                    gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
                    opacity={1}
                    zIndex={0}
                />
                <Container className={classes.containerCenterShortWithBottom}>
                    <Title className={classes.titleSmall}>
                        {t('sloganTitle4')}
                        <span className={classes.highlight}>{t('sloganTitle4Highlight')}</span>
                    </Title>

                    <List
                        mt={30}
                        spacing="sm"
                        size="lg"
                        icon={
                            <ThemeIcon size={20} radius="xl">
                                <ThumbUp size={rem(12)}/>
                            </ThemeIcon>
                        }
                    >
                        <List.Item>
                            <Title order={2} size="h4" textWrap="balance"  className={classes.description}>
                                {t('sloganDetail4-1')}
                            </Title>
                        </List.Item>
                        <List.Item>
                            <Title order={2} size="h4" textWrap="balance"  className={classes.description}>
                                {t('sloganDetail4-2')}
                            </Title>
                        </List.Item>
                        <List.Item>
                            <Title order={2} size="h4" textWrap="balance"  className={classes.description}>
                                {t('sloganDetail4-3')}
                            </Title>
                        </List.Item>
                    </List>

                </Container>
            </div>


            <div className={classes.anca}>
                <Overlay
                    gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, .25) 60%)"
                    opacity={1}
                    zIndex={0}
                />
                <Container className={classes.containerCenterShort}>
                    <Title className={classes.titleSmall}>
                        {t('sloganTitle5')}
                        <span className={classes.highlight}>{t('sloganTitle5Highlight')}</span>
                    </Title>
                    <List
                        mt={30}
                        spacing="sm"
                        size="lg"
                        icon={
                            <ThemeIcon size={20} radius="xl">
                                <Award size={rem(12)}/>
                            </ThemeIcon>
                        }
                    >
                        <List.Item>
                            <Title order={2} size="h4" textWrap="balance" className={classes.description}>
                                {t('sloganDetail5-1')}
                            </Title>
                        </List.Item>
                        <List.Item>
                            <Title  order={2} size="h4" textWrap="balance" className={classes.description}>
                                {t('sloganDetail5-2')}
                            </Title>
                        </List.Item>
                        <List.Item>
                            <Title  order={2} size="h4" textWrap="balance" className={classes.description}>
                                {t('sloganDetail5-3')}
                            </Title>
                        </List.Item>
                    </List>
                </Container>
                <div className={classes.zollerContainer}>
                    <div className={classes.zollerPad}>
                        <ANCA/>
                    </div>
                </div>
            </div>

            <div className={classes.zoller}>

                <div className={classes.zollerContainer}>
                    <div className={classes.zollerPad}>
                        <Zoller></Zoller>
                    </div>
                </div>
            </div>


            <div className={classes.mills2}>
                <Overlay
                    gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
                    opacity={1}
                    zIndex={0}
                />
                <Container className={classes.containerCenter}>
                    <Title className={classes.titleSmall}>{t('sloganTitle3')}</Title>
                    <Title  order={2} size="h4" textWrap="balance"  className={classes.description} mt="xl">
                        {t('sloganDetail3')}
                    </Title >

                    <Button variant="gradient" size="xl" radius="xl" className={classes.control} onClick={showQuotationModal}>
                        {t('order')}
                    </Button>
                </Container>
            </div>

            <div>
                <ProductsCards/>
            </div>

            {/*<div className={classes.mills2}>*/}
            {/*    <Overlay*/}
            {/*        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 60%)"*/}
            {/*        opacity={1}*/}
            {/*        zIndex={0}*/}
            {/*    />*/}
            {/*    <Container className={classes.containerCenter}>*/}
            {/*        <UseInfos/>*/}
            {/*    </Container>*/}
            {/*</div>*/}
            {/*<div className={classes.mills2}>*/}
            {/*    <Overlay*/}
            {/*        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"*/}
            {/*        opacity={1}*/}
            {/*        zIndex={0}*/}
            {/*    />*/}
            {/*    <Container className={classes.containerCenter}>*/}
            {/*        <CardsCarousel></CardsCarousel>*/}
            {/*    </Container>*/}
            {/*</div>*/}
            <div className={classes.mills3}>
                <Overlay
                    gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
                    opacity={1}
                    zIndex={0}
                />
                <Container className={classes.containerFeatures}>
                    <FeaturesCards/>
                </Container>
            </div>
            <div className={classes.mills3}>
                <Overlay
                    gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
                    opacity={1}
                    zIndex={0}
                />
                <Container className={classes.containerCenter}>
                    <TimelineComponent/>
                </Container>
            </div>
            <div className={classes.mills4}>
                <Overlay
                    gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
                    opacity={1}
                    zIndex={0}
                />
                <Container className={classes.containerFeatures}>
                    <CommentCards/>
                </Container>
            </div>
            <div className={classes.mills4} id="ContactUs">
                <Overlay
                    gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
                    opacity={1}
                    zIndex={0}
                />
                <Container className={classes.containerForm}>
                    <ContactUs/>
                </Container>
            </div>
            {
                lng === 'zh' ? <ZhMap/> : <EngMap/>
            }
            <FaqWithImage></FaqWithImage>
            <FooterLinks/>
        </>


    );
}