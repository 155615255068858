import {useParams} from "react-router-dom";
import {findStandProductByID} from "../utils/getData";
import React, {useEffect, useState} from "react";
import {Card, Descriptions, Image, Carousel, ConfigProvider} from "antd";
import {Helmet} from "react-helmet";

export function ProductPage() {
    const [productData, setProductData] = useState();

    let { lng, id, productName } = useParams();
    const fetchData = async (id) => {
        try {
            const resolvedData = await findStandProductByID(id);
            setProductData(resolvedData);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchData(id)
    }, [id]);


    return (
        <ConfigProvider
            theme={{
                components: {
                    Carousel: {
                        dotActiveWidth: 60,
                        dotHeight: 10, // 启用算法
                        dotWidth: 20
                    }
                },
            }}
        >
            <>
                {
                    productData && (
                    <div>
                        <Helmet>
                            <html lang={lng} />
                            <title>{productData.name}</title>
                            <meta name="description" content={`Best ${productData.name} Supplier from China by Kytools.tools`}/>
                            <meta name="keywords" content={`${productData.name},${productData.category},${productData.subCategory},kytools,cnc tools, china suppliers, customize`}></meta>
                            <meta name="lastmod" content={`${productData.updateTime}`}/>
                            <link rel="canonical" href=""/>
                        </Helmet>
                        <Card title={productData.name} style={{width: '50%', margin: 'auto'}}>
                            <Carousel autoplay dotPosition='bottom'>
                                <div>
                                    <Image src={productData.mainPictureLink} alt={productData.name} />
                                </div>
                                <div>
                                    <Image src={productData.subPictureLink1} alt={productData.name} />
                                </div>
                                <div>
                                    <Image src={productData.subPictureLink2} alt={productData.name} />
                                </div>
                                <div>
                                    <Image src={productData.subPictureLink3} alt={productData.name} />
                                </div>
                                <div>
                                    <Image src={productData.subPictureLink4} alt={productData.name} />
                                </div>
                                {/* Add more images if needed */}
                            </Carousel>

                            <Descriptions title="Product Details" bordered>
                                <Descriptions.Item label="Category">{productData.category}</Descriptions.Item>
                                <Descriptions.Item label="Sub Category">{productData.subCategory}</Descriptions.Item>
                                <Descriptions.Item label="Raw Material">{productData.rawMaterial}</Descriptions.Item>
                                <Descriptions.Item label="Coating">{productData.coating}</Descriptions.Item>
                                <Descriptions.Item label="Tolerance">{productData.tolerance}</Descriptions.Item>
                                <Descriptions.Item label="Feature 1">{productData.feature1}</Descriptions.Item>
                                <Descriptions.Item label="Feature 2">{productData.feature2}</Descriptions.Item>
                                <Descriptions.Item label="Suitable For">{productData.suitableFor}</Descriptions.Item>
                                <Descriptions.Item label="HRC">{productData.hrc}</Descriptions.Item>
                                <Descriptions.Item label="Coated Options">{productData.coatedOptions}</Descriptions.Item>
                                <Descriptions.Item label="Place of Origin">{productData.placeOfOrigin}</Descriptions.Item>
                                <Descriptions.Item label="Brand Name">{productData.brandName}</Descriptions.Item>
                                <Descriptions.Item label="Model Number">{productData.modelNumber}</Descriptions.Item>
                                <Descriptions.Item label="Material">{productData.material}</Descriptions.Item>
                                <Descriptions.Item label="Stock">{productData.stock}</Descriptions.Item>
                                <Descriptions.Item label="Process Type">{productData.processType}</Descriptions.Item>
                                <Descriptions.Item label="Package Delivery">{productData.packageDelivery}</Descriptions.Item>
                            </Descriptions>

                            <Card title="Description" style={{ marginTop: 16 }}>
                                <p>{productData.description || 'No description available.'}</p>
                            </Card>



                            {/* Add more sections like Coating, Tolerance, Suitable For, etc. */}
                        </Card>
                    </div>)
                }
            </>
        </ConfigProvider>
    );
}